import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {userSettings} from '../store/atoms';
import CompanyPresentationLinks from './CompanyPresentationLinks';
import CompanyPresentationUpdateSavePublishedModal from './CompanyPresentationUpdateSavePublishedModal';
import CompanyPresentationUpdateSaveUnpublishedModal from './CompanyPresentationUpdateSaveUnpublishedModal';
import CompanyPresentationUpdateCancelModal from './CompanyPresentationUpdateCancelModal';

function CompanyPresentationUpdate(setModified) {
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [presentationExist, setPresentationExist] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [isCompanyInfoExist, setIsCompanyInfoExist] = useState(false);
  const clientIntro = [
    'clientIntro_videoPresentationLink',
    'clientIntro_videoIntro',
  ];
  const [picture, setPicture] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [color, setColor] = useState(undefined);
  const [location, setLocation] = useState(undefined);
  const [companyMoreInfo, setCompanyMoreInfo] = useState(undefined);
  const [companyKey, setCompanyKey] = useState(undefined);
  const [showSavePublishedModal, setShowSavePublishedModal] = useState(false);
  const [showSaveUnpublishedModal, setShowSaveUnpublishedModal] =
    useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const validationSchema = yup.object().shape({
    clientIntro_videoPresentationLink: yup
      .string()
      .matches(
        /^((https?):\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9-_#]*)*(\?[a-zA-Z0-9-_&%]+=[a-zA-Z0-9-_&%]+(&[a-zA-Z0-9-_&%]+=[a-zA-Z0-9-_&%]+)*)?$/,
        'Enter correct url!'
      )
      .required('Please enter correct url!'),
    clientIntro_videoIntro: yup
      .string()
      .required('Video Intro is required')
      .max(250, 'Video Intro has a maximum limit of 250 characters.'),
  });

  const [characterCount, setCharacterCount] = useState(0);

  const updateCharacterCount = (fieldName, value) => {
    const count = value ? value.length : 0;
    setCharacterCount((prevCount) => ({
      ...prevCount,
      [fieldName]: count,
    }));
  };

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const watchedFields = watch();

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/presentation/findByCompanyKey/${userInfo.companyKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setPresentationExist(true);
          if (response.data[0].presentationAttributes.isPublic === true) {
            setIsPublic(true);
          }
          localStorage.setItem('presentationID', response.data[0].entityKey);
          setCompanyKey(response.data[0].companyKey);

          clientIntro.forEach((element) => {
            if (
              response.data[0].presentationAttributes &&
              response.data[0].presentationAttributes[element] !== undefined
            ) {
              setValue(
                element,
                response.data[0].presentationAttributes[element]
              );

              updateCharacterCount(
                element,
                response.data[0].presentationAttributes[element]
              );
            }
          });
          setPicture(
            response.data[0].presentationAttributes
              .startIntro_backgroundImage || null
          );
          setMessage(
            response.data[0].presentationAttributes.startIntro_overlayText ||
              null
          );
          setColor(
            response.data[0].presentationAttributes.startIntro_textColor || null
          );
          setLocation(
            response.data[0].presentationAttributes.startIntro_textLocation ||
              null
          );
          setCompanyMoreInfo(
            response.data[0].presentationAttributes
              .startIntro_companyMoreInfo || null
          );
        }
      } catch (error) {
        setPresentationExist(false);
      }
    }

    if (userInfo.companyKey) {
      fetchCompanyPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${userInfo.companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setIsCompanyInfoExist(true);
          setCompanyInfo(response.data);
        }
      } catch (error) {
        setIsCompanyInfoExist(false);
        history.push(`/company/create/info`);
      }
    }

    if (userInfo.companyKey) {
      fetchCompanyInfo();
    } else {
      setIsCompanyInfoExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updatePresentation(data) {
    const newPresentation = {
      entityKey: localStorage.getItem('presentationID'),
      companyKey,
      presentationAttributes: {
        lastSaved: 'clientIntro',
        companyName: companyInfo.companyName,
        clientIntro_videoPresentationLink:
          data.clientIntro_videoPresentationLink,
        clientIntro_videoIntro: data.clientIntro_videoIntro,
        startIntro_backgroundImage: picture,
        startIntro_overlayText: message,
        startIntro_textColor: color,
        startIntro_textLocation: location,
        startIntro_companyMoreInfo: companyMoreInfo,
        isPublic,
      },
    };

    try {
      const presentationID = localStorage.getItem('presentationID');
      const response = await axios.put(
        `${API_SERVER_URL}/api/company/presentation/updateByEntityKey/${presentationID}`,
        newPresentation,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
        const {setModified: updateModified} = setModified;
        updateModified('clientIntro');
      } else {
        setIsError(true);
        console.log('CompanyInfoUpdate update company presentation error');
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  async function addPresentation(data) {
    const newPresentation = {
      entityKey: userInfo.companyKey,
      companyKey: userInfo.companyKey,
      presentationAttributes: {
        lastSaved: 'clientIntro',
        companyName: companyInfo.companyName,
        clientIntro_videoPresentationLink:
          data.clientIntro_videoPresentationLink,
        clientIntro_videoIntro: data.clientIntro_videoIntro,
        startIntro_backgroundImage: picture,
        startIntro_overlayText: message,
        startIntro_textColor: color,
        startIntro_textLocation: location,
        startIntro_companyMoreInfo: companyMoreInfo,
      },
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/company/presentation/create`,
        newPresentation,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        localStorage.setItem('presentationID', response.data.entityKey);
        setIsSuccess(true);
        const {setModified: updateModified} = setModified;
        updateModified('clientIntro');
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  const onSubmit = async (data) => {
    setIsError(false);
    setIsSuccess(false);
    setShowSavePublishedModal(false);
    setShowSaveUnpublishedModal(false);

    if (presentationExist) {
      updatePresentation(data);
    } else {
      addPresentation(data);
    }

    window.location.reload();
  };

  const handleOnClickSaveOrAdd = (e) => {
    e.preventDefault();
    if (isPublic) {
      setShowSavePublishedModal(true);
    } else {
      setShowSaveUnpublishedModal(true);
    }
  };

  const handleOnClickCancel = (e) => {
    e.preventDefault();
    setShowCancelModal(true);
  };

  const handleCancel = () => {
    history.push(`/company/presentation/preview`);
  };

  if (!isCompanyInfoExist) {
    return (
      <Container className="shadow rounded">
        <Row>
          <Col>
            <CompanyPresentationLinks
              linkKey="linkUpdate"
              publishEnable={false}
            />
          </Col>
        </Row>
        <Row className="py-5 m-5">
          <Col>
            <h2>Please input company info first.</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="shadow rounded">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col>
            <CompanyPresentationLinks
              linkKey="linkUpdate"
              publishEnable={presentationExist}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mb-3">
              {isError && !isSuccess && (
                <small className="mt-3 d-inline-block text-danger">
                  Something went wrong. Please try again later.
                </small>
              )}
              {isSuccess && (
                <small className="mt-3 d-inline-block text-success">
                  Submit success!
                </small>
              )}
            </div>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <h4>Video Presentation Link</h4>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <textarea
              rows="1"
              type="text"
              {...register('clientIntro_videoPresentationLink')}
              className={`form-control ${
                errors.clientIntro_videoPresentationLink ? 'is-invalid' : ''
              }`}
              id="userInputVideoPresentationLink"
            />
            <div className="invalid-feedback">
              {errors.clientIntro_videoPresentationLink?.message}
            </div>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <h5>What’s exciting about your company?</h5>
          </Col>
        </Row>
        <Row className="">
          <Col>
            <textarea
              rows="5"
              maxLength="250"
              type="text"
              {...register('clientIntro_videoIntro')}
              className={`form-control ${
                errors.clientIntro_videoIntro ? 'is-invalid' : ''
              }`}
              id="userInputClientVideoIntro"
              onChange={(e) => {
                setValue('clientIntro_videoIntro', e.target.value);
                updateCharacterCount('clientIntro_videoIntro', e.target.value);
              }}
            />
            <div className="d-flex justify-content-end">
              {characterCount.clientIntro_videoIntro}/250
            </div>
            <div className="invalid-feedback">
              {errors.clientIntro_videoIntro?.message}
            </div>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <button
              type="button"
              className="mx-2 px-5 my-3 btn btn-outline-success rounded-pill"
              onClick={handleOnClickCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleOnClickSaveOrAdd}
              disabled={
                isSubmitting || !Object.values(watchedFields).some(Boolean)
              }
              className="btn btn-success px-5 my-3 rounded-pill"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1" />
              )}
              {presentationExist ? 'Save' : 'Add'}
            </button>
          </Col>
        </Row>
      </form>
      <CompanyPresentationUpdateSavePublishedModal
        showModal={showSavePublishedModal}
        handleSubmit={handleSubmit(onSubmit)}
        setShowModal={setShowSavePublishedModal}
        setIsPublic={setIsPublic}
        isPublic={isPublic}
      />
      <CompanyPresentationUpdateSaveUnpublishedModal
        showModal={showSaveUnpublishedModal}
        handleSubmit={handleSubmit(onSubmit)}
        setShowModal={setShowSaveUnpublishedModal}
      />
      <CompanyPresentationUpdateCancelModal
        showModal={showCancelModal}
        handleCancel={handleCancel}
        setShowModal={setShowCancelModal}
      />
    </Container>
  );
}

export default CompanyPresentationUpdate;
