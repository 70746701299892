import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import PropTypes from 'prop-types';
import {Row, Col, ButtonGroup, Button} from 'react-bootstrap';
import {useRecoilValue, useRecoilState} from 'recoil';
import {
  userSettings,
  talentFavoriteJobListState,
  talentApplicationListState,
} from '../store/atoms';
import {JobInformationCard} from './JobInformationCard';
import JobApplyCard from '../talent/JobApplyCard';
import {FindJobMessageModal} from './message/FindJobMessageModal';
import useTalentApplicationList from './utils/useTalentApplicationList';
import useTalentFavoriteJobList from './utils/useTalentFavoriteJobList';

// Define the StyledButton component
const StyledButton = styled.button`
  width: 144px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 45px;
  border-radius: 32px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  background-color: ${({buttonType}) => {
    switch (buttonType) {
      case 'Apply':
        return '#f1881a'; // Orange
      case 'Applied':
        return '#4CAF50'; // Green
      case 'Draft':
        return '#C0C0C0'; // Silver
      default:
        return '#C0C0C0'; // Default to Orange if no match
    }
  }};
`;

function JobCard({job}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [canViewMore, setCanViewMore] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteKey, setFavoriteKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  useTalentApplicationList(userInfo); // This will fetch and set the applied job list
  const talentApplicationList = useRecoilValue(talentApplicationListState);
  const [applyJobShow, setApplyJobShow] = useState(false);
  const [applyButtonText, setApplyButtonText] = useState('Apply');
  useTalentFavoriteJobList(userInfo); // This will fetch and set the favorite job list
  const [talentFavoriteJobList, setTalentFavoriteJobList] = useRecoilState(
    talentFavoriteJobListState
  );
  const jobTextStyle = {
    color: 'orange',
    fontWeight: 'bold',
  };

  useEffect(() => {
    if (talentFavoriteJobList && talentFavoriteJobList.length > 0) {
      talentFavoriteJobList.forEach((item) => {
        if (item.jobKey === job.entityKey) {
          setIsFavorite(true);
          setFavoriteKey(item.entityKey);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentFavoriteJobList]);

  async function addToFavorite(jobKey) {
    const newFavorite = {
      userKey: userInfo.userKey,
      jobKey,
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/favorite/job/addFavorite`,
        newFavorite,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setTalentFavoriteJobList((oldFavoriteCompanyList) => [
          ...oldFavoriteCompanyList,
          response.data,
        ]);
      }
    } catch (error) {
      console.log('TalentFavoriteJob add new favorite cpmpany error -', error);
    }
  }

  async function deleteFavorite(favoriteEntityKey) {
    // console.log("Talent click delete the favorite - ", favoriteEntityKey);
    try {
      const response = await axios.delete(
        `${API_SERVER_URL}/api/talent/favorite/job/deleteFavoriteByEntityKey/${favoriteEntityKey}`,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        const favoriteIndex = talentFavoriteJobList.findIndex(
          (listItem) => listItem.entityKey === favoriteEntityKey
        );
        const newFavoriteList = [
          ...talentFavoriteJobList.slice(0, favoriteIndex),
          ...talentFavoriteJobList.slice(favoriteIndex + 1),
        ];
        setTalentFavoriteJobList(newFavoriteList);
      }
    } catch (error) {
      console.log('TalentFavoriteJob delete favorite company error -', error);
    }
  }

  useEffect(() => {
    if (talentApplicationList) {
      const applyIndex = talentApplicationList.findIndex(
        (listItem) => listItem.jobKey === job.entityKey
      );
      if (applyIndex > -1) {
        if (
          talentApplicationList[applyIndex].appliedJobAttributes?.status ===
          'applied'
        ) {
          setApplyButtonText('Applied');
        } else if (
          talentApplicationList[applyIndex].appliedJobAttributes?.status ===
          'draft'
        ) {
          setApplyButtonText('Draft');
        } else {
          setApplyButtonText('Apply');
        }
      } else {
        setApplyButtonText('Apply');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentApplicationList]);

  return (
    <Col md={12} className="my-3">
      <div className="bg-light pt-3">
        <div className="d-flex justify-content-between align-items-center">
          <h4>
            <span style={jobTextStyle}>{job?.jobTitle}</span>
          </h4>
          <StyledButton
            buttonType={applyButtonText}
            onClick={() => setApplyJobShow(true)}
          >
            {applyButtonText}
          </StyledButton>
        </div>

        <div>
          <JobInformationCard job={job} full={canViewMore} />
        </div>

        <Row>
          <Col md={4} className="text-left">
            {job?.numApplicants && job?.numApplicants > 0 ? (
              <Button variant="light">
                <span className="badge badge-default">
                  <i className="bi bi-lightbulb mr-1" />
                  {job?.numApplicants} applicant
                  {job?.numApplicants > 1 ? 's' : ''}
                </span>
              </Button>
            ) : (
              <Button variant="light">
                <span className="badge badge-warning">
                  <i className="bi bi-lightbulb mr-1" />
                  Be the first applicant
                </span>
              </Button>
            )}
          </Col>
          <Col md={8} className="text-right text-success">
            <ButtonGroup>
              <Button
                variant="light"
                className="text-success"
                onClick={() => {
                  setCanViewMore(!canViewMore);
                }}
              >
                {canViewMore ? (
                  <i className="bi bi-three-dots-vertical" />
                ) : (
                  <i className="bi bi-three-dots" />
                )}
              </Button>

              {isFavorite ? (
                <Button
                  variant="light"
                  className="text-success"
                  onClick={() => {
                    deleteFavorite(`${favoriteKey}`);
                    setIsFavorite(false);
                  }}
                >
                  <i className="bi bi-heart-fill" style={{color: 'green'}} />
                </Button>
              ) : (
                <Button
                  variant="light"
                  className="text-success"
                  onClick={() => addToFavorite(`${job.entityKey}`)}
                >
                  <i className="bi bi-heart" />
                </Button>
              )}
              <Button
                variant="light"
                className="text-success"
                onClick={() => setShowModal(!showModal)}
              >
                <i className="bi bi-chat-square-dots" />
              </Button>
              {showModal && (
                <FindJobMessageModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  item={job}
                  toType="COMPANY"
                />
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      {applyJobShow && (
        <JobApplyCard
          job={job}
          showApplyModal={applyJobShow}
          onHideApplyModal={() => setApplyJobShow(false)}
        />
      )}
    </Col>
  );
}

JobCard.propTypes = {
  job: PropTypes.object.isRequired,
};

export default JobCard;
