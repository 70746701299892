import React from 'react';
import PropTypes from 'prop-types';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from 'styled-components';
import {PresentationTypes} from './Utils/PresentationTypes';

const VideoPresentationImageContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const VideoPresentationImageBackground = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VideoPresentationOverlayText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({textColor}) => textColor};
  text-align: ${({textLocation}) =>
    textLocation ? textLocation.toLowerCase() : 'center'};
`;

function VideoPresentationImage({showOverlayText, video}) {
  const DefautBackgroudImage = `/images/Defaut-image-with-play-button.png`;
  const DefautBackgroudImageWithPlayButton = `/images/Defaut-image-with-play-button.png`;
  const renderImage = () => {
    const {coverURL, hasVideo, lastSaved} = video;
    let imageUrl = '';
    if (lastSaved === PresentationTypes.StartIntro) {
      imageUrl =
        video.startIntro_backgroundImage.includes('images/') &&
        video.startIntro_backgroundImage
          ? video.startIntro_backgroundImage
          : DefautBackgroudImage;
    } else if (lastSaved === PresentationTypes.ClientIntro) {
      imageUrl = hasVideo ? coverURL : DefautBackgroudImageWithPlayButton;
    } else {
      imageUrl = hasVideo
        ? DefautBackgroudImageWithPlayButton
        : DefautBackgroudImage;
    }

    return (
      <VideoPresentationImageBackground
        alt="Video presentation image"
        src={imageUrl}
        effect="blur"
      />
    );
  };

  const renderOverlayText = () => {
    if (!video.hasVideo && showOverlayText) {
      return (
        <VideoPresentationOverlayText
          textColor={video.startIntro_textColor}
          textLocation={video.startIntro_textLocation}
        >
          {video.startIntro_overlayText}
        </VideoPresentationOverlayText>
      );
    }
    return null;
  };

  return (
    <VideoPresentationImageContainer>
      {renderImage()}
      {renderOverlayText()}
    </VideoPresentationImageContainer>
  );
}

VideoPresentationImage.propTypes = {
  showOverlayText: PropTypes.bool.isRequired,
  video: PropTypes.object.isRequired,
};

export default VideoPresentationImage;
