import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';

function CompanyPresentationUpdateSavePublishedModal() {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    sessionStorage.setItem('publishPresentationPromptHasShown', true);
  };

  return (
    <Modal
      autoFocus
      backdrop="static"
      show={show}
      onHide={handleClose}
      size="md"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <p>
          Please go to the Present menu to have a presentation published so your
          company and its posted positions can be found by talents in the
          platform.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default CompanyPresentationUpdateSavePublishedModal;
