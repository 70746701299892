import React, {useState, useEffect} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';
import axios from 'axios';
import {BsEyeFill, BsEyeSlashFill} from 'react-icons/bs';
import {userSettings} from '../store/atoms';
import AccountNotVerified from './AccountNotVerified';

function SideContent() {
  return (
    <Col
      xs={5}
      className="d-none d-sm-block text-center"
      style={{
        marginTop: '10em',
        color: '#585757',
      }}
    >
      <h3 className="mt-3 mx-4">
        The place where remote talents and American startup companies join
        forces to build the future.
      </h3>
    </Col>
  );
}

function SignIn() {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isNotVerified, setIsNotVerified] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const setUserInfo = useSetRecoilState(userSettings);
  const [userLoginInfo, setUserLoginInfo] = useState({});
  const history = useHistory();
  const API_SERVER_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const {userAgent} = navigator;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
      setIsMobile(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setIsError(false);
    try {
      const user = {username: emailAddress, password};
      const [signInGetUserTokenResponse] = await Promise.all([
        axios.post(`${API_SERVER_URL}/api/user/login`, user),
      ]);
      if (signInGetUserTokenResponse.status === 200) {
        const {token} = signInGetUserTokenResponse.data;
        const userKey = signInGetUserTokenResponse.data.key;
        localStorage.setItem('token', token);
        localStorage.setItem('userKey', userKey);
        localStorage.setItem('entityKey', userKey);

        const [signInGetUserSettingsResponse] = await Promise.all([
          axios.get(`${API_SERVER_URL}/api/user/findByUserKey/${userKey}`, {
            headers: {Authorization: `Bearer ${token}`},
          }),
        ]);

        const {userTypes} = signInGetUserSettingsResponse.data;
        const {currentType} = signInGetUserSettingsResponse.data;
        if (userTypes.includes(currentType)) {
          userLoginInfo.authenticated = true;
          userLoginInfo.token = token;
          userLoginInfo.companyKey = '';
          userLoginInfo.userKey = userKey;
          userLoginInfo.firstName =
            signInGetUserSettingsResponse.data.firstName;
          userLoginInfo.lastName = signInGetUserSettingsResponse.data.lastName;
          userLoginInfo.emailAddress = emailAddress;
          userLoginInfo.userTypes = userTypes;
          userLoginInfo.loggedInAsUserType = currentType;
          setUserLoginInfo(userLoginInfo);
          setUserInfo(userLoginInfo);

          localStorage.setItem('authenticated', true);
          localStorage.setItem(
            'firstName',
            signInGetUserSettingsResponse.data.firstName
          );
          localStorage.setItem(
            'lastName',
            signInGetUserSettingsResponse.data.lastName
          );
          localStorage.setItem('emailAddress', emailAddress);
          localStorage.setItem('userTypes', userTypes);
          localStorage.setItem('loggedInAsUserType', currentType);

          setEmailAddress('');
          setPassword('');
        }

        setLoading(false);

        if (currentType === 'TALENT') {
          try {
            const findTalentUserResponse = await axios.get(
              `${API_SERVER_URL}/api/talent/user/findByUserKey/${userKey}`,
              {headers: {Authorization: `Bearer ${token}`}}
            );
            if (findTalentUserResponse.status === 200) {
              history.push(`/talent/startmedia`);
            }
          } catch (error) {
            history.push(`/talent/info`);
          }
        } else if (currentType === 'INVESTOR') {
          try {
            const findInvestorUserResponse = await axios.get(
              `${API_SERVER_URL}/api/investor/user/findByUserKey/${userKey}`,
              {headers: {Authorization: `Bearer ${token}`}}
            );
            if (findInvestorUserResponse.status === 200) {
              history.push(`/investor/company/find`);
            }
          } catch (error) {
            history.push(`/investor/user`);
          }
        } else if (currentType === 'COMPANY') {
          // Step 1: find company user by user key.
          try {
            const findCompanyUserResponse = await axios.get(
              `${API_SERVER_URL}/api/company/user/findByUserKey/${userKey}`,
              {headers: {Authorization: `Bearer ${token}`}}
            );
            if (findCompanyUserResponse.status === 200) {
              const {companyKey} = findCompanyUserResponse.data;
              localStorage.setItem('companyKey', companyKey);
              if (companyKey) {
                // Step 2: find company by company key.
                try {
                  const findCompanyResponse = await axios.get(
                    `${API_SERVER_URL}/api/company/findByEntityKey/${companyKey}`,
                    {headers: {Authorization: `Bearer ${token}`}}
                  );
                  if (findCompanyResponse.status === 200) {
                    if (
                      findCompanyUserResponse.data.companyUserAttributes
                        .title &&
                      findCompanyUserResponse.data.companyUserAttributes
                        .email &&
                      findCompanyUserResponse.data.companyUserAttributes
                        .phoneNumber
                    ) {
                      history.push(`/company/talents/find`);
                    } else {
                      history.push(`/company/user`);
                    }
                  }
                } catch (error) {
                  history.push(`/company/create/info`);
                }
              } else {
                history.push(`/company/create/info`);
              }
            }
          } catch (error) {
            history.push(`/company/create/info`);
          }
        } else {
          history.push(`/`);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setIsNotVerified(true);
        } else if (error.response.status === 401) {
          setIsError(true);
        } else {
          setIsError(true);
        }
      } else {
        console.log('Sigin login error - ', error.response);
      }
      setLoading(false);
    }
  };

  if (isNotVerified) {
    return <AccountNotVerified />;
  }

  return (
    <Container
      fluid
      style={{
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, #F1881A 3%, #fff 100%)',
        backgroundSize: '100% 30px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Row>
        <SideContent />
        <Col
          sm={6}
          className="mr-5"
          style={{
            color: '#585757',
          }}
        >
          <form className="mt-5" onSubmit={handleSubmit}>
            <div className="mt-3 mb-3 text-center">
              <h2 style={{color: 'black'}}>HI! Welcome Back</h2>
              {isMobile && (
                <h2 style={{color: 'black', fontSize: '75%'}}>
                  (Desktop browser recommended)
                </h2>
              )}
              {isError && (
                <small className="mt-3 d-inline-block text-danger">
                  Invalid credentials. Please try again.
                </small>
              )}
            </div>
            <div className="my-4">
              <label className="form-label">E-mail</label>
              <input
                type="email"
                className="form-control"
                id="userInputEmail"
                aria-describedby="emailHelp"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                required
                style={{borderRadius: '8px'}}
              />
            </div>
            <div className="mb-5">
              <div className="d-flex">
                <label className="form-label">Password</label>
                <div
                  className="password-icon"
                  role="button"
                  tabIndex={0}
                  onClick={() => setVisible(!visible)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setVisible(!visible);
                    }
                  }}
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                  }}
                >
                  {visible ? <BsEyeFill /> : <BsEyeSlashFill />} Hide
                </div>
              </div>
              <input
                type={visible ? 'text' : 'password'}
                className="form-control"
                id="userInputPassword"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{borderRadius: '8px'}}
              />
              <div className="d-flex justify-content-between">
                <div className="form-check mt-2 mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberMeCheckbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="rememberMeCheckbox"
                  >
                    <small>Remember me</small>
                  </label>
                </div>
                <div className="mt-2 mb-2">
                  <NavLink to="/forgot" className="ml-3">
                    <small style={{color: '#F1881A'}}>Forgot Password?</small>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="mb-3 d-flex justify-content-center">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-success rounded-pill"
                style={{
                  width: '200px',
                  padding: '10px 30px',
                  backgroundColor: '#F1881A',
                  color: 'white',
                  border: 'none',
                }}
              >
                {loading ? 'Please waiting...' : 'Login'}
              </button>
            </div>
            <div className="mt-3 d-flex justify-content-center">
              <NavLink to="/signup">
                <small
                  style={{
                    color: '#585757',
                    textDecoration: 'underline',
                    margin: '4px',
                  }}
                >
                  Don&apos;t have an account?
                </small>
                <small
                  style={{
                    color: '#F1881A',
                    textDecoration: 'underline',
                  }}
                >
                  Sign Up
                </small>
              </NavLink>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;
