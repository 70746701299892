import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import uuid from 'react-uuid';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import Post from './post';
import {
  userSettings,
  searchCompanyResultState,
  searchCompanyResultVideosState,
} from '../store/atoms';
import useMultiKeywordSearch from '../common/utils/UseMultiKeywordSearch';

function Media() {
  const userInfo = useRecoilValue(userSettings);
  const SEARCH_URL = `${process.env.REACT_APP_API_URL}/api/company/findCompanyPresentationsAndJobsByKeyWordList`;
  const [shouldSearch, setShouldSearch] = useState(true);

  // Call the hook, but it will only execute when `shouldSearch` is true
  const {error, loading, presentations, resultData} = useMultiKeywordSearch(
    shouldSearch ? [''] : null,
    SEARCH_URL,
    userInfo.token
  );
  const setSearchResult = useSetRecoilState(searchCompanyResultState);
  const setVideos = useSetRecoilState(searchCompanyResultVideosState);

  useEffect(() => {
    if (shouldSearch) {
      if (resultData && presentations && !loading) {
        setSearchResult(resultData);
        setVideos(presentations);
        setShouldSearch(false); // Prevent re-fetching
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData, presentations, loading]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Search Error, please try again</p>;

  return (
    <Container className="">
      <Row>
        <Col md={12}>
          {error && (
            <div>
              <p>{error.message}</p>
            </div>
          )}
        </Col>
      </Row>

      {loading ? (
        <p>loading...</p>
      ) : (
        <InfiniteScroll
          dataLength={presentations.length}
          hasMore
          Loader={
            <div className="spinner-border text-danger d-block mx-auto" />
          }
          className="videos_previews_container"
        >
          {presentations &&
            presentations.map((video) => <Post video={video} key={uuid()} />)}
        </InfiniteScroll>
      )}
    </Container>
  );
}

export default Media;
