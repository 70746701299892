import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import {Row, Col, Card, ListGroup} from 'react-bootstrap';
import fp from 'lodash/fp';
import moment from 'moment';
import {userSettings} from '../../store/atoms';

export function MessageSent() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    setMessageList([]);
    async function fetchSentMessage() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/findBySent/${
            userInfo.userKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          const sortedMessages = response.data.sort(
            (a, b) =>
              new Date(b.sentMessageAttributes.modifyTime) - new Date(a.sentMessageAttributes.modifyTime)
          );
          setMessageList(
            Object.entries(
              fp.groupBy('sentMessageAttributes.id')(sortedMessages)
            )
          );
        }
      } catch (error) {
        console.error('Failed to get sent messages - ', error);
      }
    }

    if (userInfo.userKey) {
      fetchSentMessage();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <p>Message Sent</p>
      <Row className="mt-1">
        {messageList.length > 0 &&
          messageList.map((item) => (
            <MessageCard
              key={item.entityKey}
              message={item}
              userInfo={userInfo}
              apiServer={API_SERVER_URL}
              messageList={messageList}
              setMessageList={setMessageList}
            />
          ))}
      </Row>
    </>
  );
}

function MessageCard({
  apiServer,
  message,
  messageList,
  setMessageList,
  userInfo,
}) {
  const [name, setName] = useState('');
  useEffect(() => {
    async function fetchTalentInfo(userKey) {
      try {
        const response = await axios.get(
          `${apiServer}/api/user/findByUserKey/${userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          let fName = '';
          let lName = '';
          if (response.data.firstName) {
            fName =
              response.data.firstName.charAt(0).toUpperCase() +
              response.data.firstName.substring(1);
          }
          if (response.data.lastName) {
            lName = response.data.lastName.charAt(0).toUpperCase();
          }
          setName(`${fName} ${lName} `);
        }
      } catch (error) {
        console.log('Find talent info error - ', error);
      }
    }

    if (userInfo.userKey && message[1][0].toUserType === 'TALENT') {
      fetchTalentInfo(message[1][0].toUserKey);
    }
    // eslint-disable-next-line
  }, []);

  const handleDelete = () => {
    async function deleteMessage(entityKey) {
      try {
        const response = await axios.delete(
          `${apiServer}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/send/deleteByEntityKey/${entityKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          if (message[1].length === 1) {
            setMessageList(
              messageList.filter((msg) => msg[1][0].entityKey !== entityKey)
            );
          } else {
            setMessageList(messageList.filter((msg) => msg[0] !== message[0]));
          }
        }
      } catch (error) {
        console.error('Failed to get draft messages - ', error);
      }
    }
    if (userInfo.userKey) {
      if (message[1].length === 1) {
        deleteMessage(message[1][0].entityKey);
      } else {
        message[1].forEach((element) => {
          deleteMessage(element.entityKey);
        });
      }
    }
  };

  return (
    <>
      {message && message[1].length === 1 && (
        <Col md={12} className="my-3">
          <Card className="bg-light">
            {message[1][0].toUserType === 'COMPANY' &&
              !message[1][0].sentMessageAttributes?.jobTitle && (
                <Card.Header>Message To Company</Card.Header>
              )}
            {message[1][0].toUserType === 'COMPANY' &&
              message[1][0].sentMessageAttributes?.jobTitle && (
                <Card.Header>Message To Company For Job</Card.Header>
              )}
            {message[1][0].toUserType === 'TALENT' &&
              !message[1][0].sentMessageAttributes?.jobTitle && (
                <Card.Header>Message To {name} (Talent):</Card.Header>
              )}
            {message[1][0].toUserType === 'TALENT' &&
              message[1][0].sentMessageAttributes?.jobTitle && (
                <Card.Header>Message To {name} (Talent) For A Job:</Card.Header>
              )}
            {message[1][0].toUserType === 'INVESTOR' && (
              <Card.Header>
                Message To Investor:{message[1][0].fromUserKey}
              </Card.Header>
            )}
            <Card.Body>
              <dl className="row my-3">
                {userInfo.loggedInAsUserType !== 'COMPANY' && (
                  <>
                    <dt className="col-sm-4 text-muted">Company Name:</dt>
                    <dd className="col-sm-8">
                      {message[1][0].sentMessageAttributes.name}
                    </dd>
                  </>
                )}
                {message[1][0].toUserType === 'COMPANY' &&
                  message[1][0].sentMessageAttributes?.jobTitle && (
                    <>
                      <dt className="col-sm-4 text-muted">Job Title:</dt>
                      <dd className="col-sm-8">
                        {message[1][0].sentMessageAttributes?.jobTitle}
                      </dd>
                    </>
                  )}
                <dt className="col-sm-4 text-muted">Sent Time:</dt>
                <dd className="col-sm-8">
                  {moment(
                    message[1][0].sentMessageAttributes?.modifyTime
                  ).format('MMMM Do YYYY, h:mm:ss a')}
                </dd>

                <dt className="col-sm-4 text-muted">Subject:</dt>
                <dd className="col-sm-8">
                  {message[1][0].sentMessageAttributes?.subject}
                </dd>

                <dt className="col-sm-4 text-muted">Message:</dt>
                <dd className="col-sm-8">
                  {message[1][0].sentMessageAttributes?.body}
                </dd>
              </dl>
              <button
                type="button"
                className="btn btn-md btn-outline-success rounded-pill"
                onClick={handleDelete}
              >
                Delete
              </button>
            </Card.Body>
          </Card>
        </Col>
      )}
      {message && message[1].length > 1 && (
        <Col md={12} className="my-3">
          <Card className="bg-light">
            {message[1][0].toUserType === 'COMPANY' &&
              !message[1][0].sentMessageAttributes?.jobTitle && (
                <Card.Header>Message To Company</Card.Header>
              )}
            {message[1][0].toUserType === 'COMPANY' &&
              message[1][0].sentMessageAttributes?.jobTitle && (
                <Card.Header>Message To Company For Job</Card.Header>
              )}
            {message[1][0].toUserType === 'TALENT' &&
              !message[1][0].sentMessageAttributes?.jobTitle && (
                <Card.Header>Message To {name}(Talent):</Card.Header>
              )}
            {message[1][0].toUserType === 'TALENT' &&
              message[1][0].sentMessageAttributes?.jobTitle && (
                <Card.Header>Message To {name}(Talent) For A Job:</Card.Header>
              )}
            {message[1][0].toUserType === 'INVESTOR' && (
              <Card.Header>
                Message To Investor:{message[1][0].fromUserKey}
              </Card.Header>
            )}
            <Card.Body>
              <dl className="row my-3">
                {userInfo.loggedInAsUserType !== 'COMPANY' && (
                  <>
                    <dt className="col-sm-4 text-muted">Company Name:</dt>
                    <dd className="col-sm-8">
                      {message[1][0].sentMessageAttributes.name}
                    </dd>
                  </>
                )}

                {message[1][0].toUserType === 'COMPANY' &&
                  message[1][0].sentMessageAttributes?.jobTitle && (
                    <>
                      <dt className="col-sm-4 text-muted">Job Title:</dt>
                      <dd className="col-sm-8">
                        {message[1][0].sentMessageAttributes?.jobTitle}
                      </dd>
                    </>
                  )}
                {message[1][0].toUserType === 'TALENT' &&
                  message[1][0].sentMessageAttributes?.jobTitle && (
                    <>
                      <dt className="col-sm-4 text-muted">Job Title:</dt>
                      <dd className="col-sm-8">
                        {message[1][0].sentMessageAttributes?.jobTitle}
                      </dd>
                    </>
                  )}
              </dl>
              <ListGroup variant="flush">
                {message[1].map((item) => (
                  // eslint-disable-next-line react/jsx-key
                  <ListGroup.Item>
                    <dl className="row my-3">
                      <dt className="col-sm-4 text-muted">Sent Time:</dt>
                      <dd className="col-sm-8">
                        {moment(item.sentMessageAttributes?.modifyTime).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      </dd>

                      <dt className="col-sm-4 text-muted">Subject:</dt>
                      <dd className="col-sm-8">
                        {item.sentMessageAttributes?.subject}
                      </dd>

                      <dt className="col-sm-4 text-muted">Message:</dt>
                      <dd className="col-sm-8">
                        {item.sentMessageAttributes?.body}
                      </dd>
                    </dl>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
            <Card.Footer>
              <button
                type="button"
                className="btn btn-md btn-outline-success rounded-pill"
                onClick={handleDelete}
              >
                Delete
              </button>
            </Card.Footer>
          </Card>
        </Col>
      )}
    </>
  );
}

MessageCard.propTypes = {
  message: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  apiServer: PropTypes.string.isRequired,
  messageList: PropTypes.object.isRequired,
  setMessageList: PropTypes.func.isRequired,
};

export default MessageCard;
