import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {Container} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import axios from 'axios';

import {userSettings} from '../store/atoms';
import CompanyLayoutHeader from '../layout/CompanyLayoutHeader';
import CompanyFindTalents from './CompanyFindTalents';
import CompanyUser from './CompanyUser';
import CompanyPresentation from './CompanyPresentation';
import CompanyPresentationUpdate from './CompanyPresentationUpdate';
import CompanyPresentationPublish from './CompanyPresentationPublish';
import CompanyPresentationUpdateInstaIntro from './CompanyPresentationUpdateInstaIntro';
import CompanyJobAdd from './CompanyJobAdd';
import CompanyInfoUpdate from './CompanyInfoUpdate';
import CompanyEquityInfoUpdate from './CompanyEquityInfoUpdate';
import CompanyPresentationPromptModal from './CompanyPresentationPromptModal';
// import CompanyEquicoin from './CompanyEquicoin';
import MessageInbox from '../common/message/MessageInbox';
import {MessageSent} from '../common/message/MessageSent';
import MessageDraft from '../common/message/MessageDraft';
import UserFeedbackInbox from '../common/feedback/UserFeedbackInbox';
import UserFeedbackSent from '../common/feedback/UserFeedbackSent';
import UserFeedbackDraft from '../common/feedback/UserFeedbackDraft';
import AccountInfoManagement from '../common/AccountInfoManagement';

function CompanyMain() {
  const userInfo = useRecoilValue(userSettings);
  const {path} = useRouteMatch();
  const [modified, setModified] = useState(undefined);
  const [showPublishPresentationPrompt, setShowPublishPresentationPrompt] =
    useState(false);
  const publishPresentationPromptHasShown = sessionStorage.getItem(
    'publishPresentationPromptHasShown'
  );

  const API_SERVER_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/presentation/findByCompanyKey/${userInfo.companyKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          if (
            response.data[0].presentationAttributes.isPublic === true ||
            publishPresentationPromptHasShown
          ) {
            setShowPublishPresentationPrompt(false);
          } else {
            setShowPublishPresentationPrompt(true);
          }
        }
      } catch (error) {
        setShowPublishPresentationPrompt(false);
      }
    }
    if (userInfo.companyKey) {
      fetchCompanyPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPublishPresentationPrompt]);

  return (
    <>
      <CompanyLayoutHeader modified={modified} />
      <Container>
        <Router>
          <Switch>
            <Route exact path={path} component={CompanyFindTalents} />
            <Route
              exact
              path={`${path}/talents/find`}
              component={CompanyFindTalents}
            />
            <Route path={`${path}/create/info`} component={CompanyInfoUpdate} />
            <Route
              path={`${path}/create/equity`}
              component={CompanyEquityInfoUpdate}
            />
            {/* <Route path={`${path}/equicoin`} component={CompanyEquicoin} /> */}
            <Route
              exact
              path={`${path}/presentation/preview`}
              component={CompanyPresentation}
            />
            <Route
              exact
              path={`${path}/presentation/update`}
              component={CompanyPresentationUpdate}
            >
              <CompanyPresentationUpdate setModified={setModified} />
            </Route>

            <Route
              exact
              path={`${path}/presentation/publish`}
              component={CompanyPresentationPublish}
            />
            <Route exact path={`${path}/presentation/update_instaIntro`}>
              <CompanyPresentationUpdateInstaIntro setModified={setModified} />
            </Route>
            <Route
              path={`${path}/create/job/:jobId?`}
              component={CompanyJobAdd}
            />
            <Route
              exact
              path={`${path}/account`}
              component={AccountInfoManagement}
            />
            <Route exact path={`${path}/user`} component={CompanyUser} />
            <Route
              exact
              path={`${path}/message/inbox`}
              component={MessageInbox}
            />
            <Route
              exact
              path={`${path}/message/sent`}
              component={MessageSent}
            />
            <Route
              exact
              path={`${path}/message/draft`}
              component={MessageDraft}
            />
            <Route
              exact
              path={`${path}/user-feedback/inbox`}
              component={UserFeedbackInbox}
            />
            <Route
              exact
              path={`${path}/user-feedback/sent`}
              component={UserFeedbackSent}
            />
            <Route
              exact
              path={`${path}/user-feedback/draft`}
              component={UserFeedbackDraft}
            />
          </Switch>
        </Router>
        {showPublishPresentationPrompt && <CompanyPresentationPromptModal />}
      </Container>
    </>
  );
}

export default CompanyMain;
