import React, {useEffect, useState} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {Alert, Card, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useRecoilValue} from 'recoil';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import {userSettings} from '../store/atoms';

const API_SERVER_URL = process.env.REACT_APP_API_URL;
const MAXIMUM_PROFILES_NUMBER =
  process.env.REACT_APP_TALENT_MVP_MAXIMUM_PROFILES;
const attributes = [
  'summary',
  'workExperience',
  'skills',
  'certifications',
  'patents',
  'publications',
  'languages',
  'education',
  'linkedInLink',
  'resumeLink',
  'videoLink',
];

function WelcomeTextForNewAdd() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Hey, nice to see you!</Alert.Heading>
        <p>
          Create your professional profile below by providing information about
          your education, career experience, skills or any other relevant
          information that make you stand out in a crowd!
        </p>
      </Alert>
    );
  }

  return null;
}

function TalentProfileAddEdit() {
  const userInfo = useRecoilValue(userSettings);
  const [isError, setIsError] = useState(false);
  const [openToPublic, setOpenToPublic] = useState(false);
  const history = useHistory();
  const {profileId} = useParams();
  const isAddMode = !profileId;
  const [enableProfileFields, setEnableProfileFields] = useState(false);

  const validationSchema = yup.object().shape({
    profileName: yup.string().required('Profile Name is required'),
    summary: yup
      .string()
      .max(512, 'Summary has a maximum limit of 512 characters.'),
    workExperience: yup
      .string()
      .max(1024, 'Work Experience has a maximum limit of 1024 characters.'),
    skills: yup
      .string()
      .max(512, 'Skills has a maximum limit of 512 characters.'),
    certifications: yup
      .string()
      .max(512, 'Certifications has a maximum limit of 512 characters.'),
    patents: yup
      .string()
      .max(512, 'Patents has a maximum limit of 512 characters.'),
    publications: yup
      .string()
      .max(512, 'Publications has a maximum limit of 512 characters.'),
    languages: yup
      .string()
      .max(128, 'Languages has a maximum limit of 128 characters.'),
    education: yup
      .string()
      .max(512, 'education has a maximum limit of 512 characters.'),
    other: yup
      .string()
      .max(512, 'Other has a maximum limit of 512 characters.'),
    linkedInLink: yup.string().url('Please enter correct url!'),
    resumeLink: yup.string().url('Please enter correct url!'),
    // gitHubLink: yup.string().url('Please enter correct url!'),
    videoLink: yup.string().url('Please enter correct url!'),
    // otherLink: yup.string().url('Please enter correct url!'),
    otherLinks: yup.array().of(
      yup.object().shape({
        linkName: yup.string().required('Link name is required'),
        linkUrl: yup
          .string()
          .url('Please enter correct url!')
          .required('Link url is required'),
      })
    ),
    additionalInfo: yup.array().of(
      yup.object().shape({
        infoName: yup.string().required('Additional info title is required'),
        infoBody: yup.string().required('Additional info body is required'),
      })
    ),
  });

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
    reset,
    setValue,
    unregister,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  async function fetchTalentProfile(talentProfileID, action) {
    try {
      const response = await axios.get(
        `${API_SERVER_URL}/api/talent/profile/findByEntityKey/${talentProfileID}`,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        console.log(
          'TalentProfileAddEdit found talent profile - ',
          response.data.profileName
        );
        if (response.data) {
          const newProfileName =
            action === 'update'
              ? response.data.profileName
              : `Copy of ${response.data.profileName}`;
          setValue('profileName', newProfileName);
          setOpenToPublic(response.data.profileAttributes.isPublic === 'true');
          attributes.forEach((element) => {
            setValue(element, response.data.profileAttributes[element]);
          });
          const otherLinksArray = response.data?.profileAttributes?.otherLinks
            ? JSON.parse(response.data.profileAttributes.otherLinks)
            : [];
          const additionalInfoArray = response.data?.profileAttributes
            ?.additionalInfo
            ? JSON.parse(response.data.profileAttributes.additionalInfo)
            : [];

          if (otherLinksArray.length > 0) {
            setValue('numberOfOtherLinks', otherLinksArray.length);
            setValue('otherLinks', otherLinksArray);
          }
          if (additionalInfoArray.length > 0) {
            setValue('numberOfAdditionalInfo', additionalInfoArray.length);
            setValue('additionalInfo', additionalInfoArray);
          }
        }
      } else if (action === 'update') {
        history.push(`/talent/profiles?from=update`);
      }
    } catch (error) {
      if (action === 'update') {
        history.push(`/talent/profiles?from=update`);
      }
    }
  }

  useEffect(() => {
    if (!isAddMode) {
      fetchTalentProfile(profileId, 'update');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  async function addProfile(data) {
    const newProfile = {
      userKey: userInfo.userKey,
      profileName: data.profileName,
      profileAttributes: {
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        isPublic: `${openToPublic}`,
        otherLinks: data?.otherLinks
          ? JSON.stringify(data.otherLinks.slice(0, data.numberOfOtherLinks))
          : JSON.stringify([]),
        additionalInfo: data?.additionalInfo
          ? JSON.stringify(
              data.additionalInfo.slice(0, data.numberOfAdditionalInfo)
            )
          : JSON.stringify([]),
      },
    };

    attributes.forEach((element) => {
      newProfile.profileAttributes[element] = data[element];
    });

    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/profile/create`,
        newProfile,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        localStorage.setItem('talentPageAction', 'add');
        history.push(`/talent/profiles?from=add`);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  }

  async function updateProfile(data) {
    const newProfile = {
      entityKey: profileId,
      profileName: data.profileName,
      profileAttributes: {
        userName: `${userInfo.firstName} ${userInfo.lastName}`,
        isPublic: `${openToPublic}`,
        otherLinks: data?.otherLinks
          ? JSON.stringify(data.otherLinks.slice(0, data.numberOfOtherLinks))
          : '',
        additionalInfo: data?.additionalInfo
          ? JSON.stringify(
              data.additionalInfo.slice(0, data.numberOfAdditionalInfo)
            )
          : '',
      },
    };
    attributes.forEach((element) => {
      newProfile.profileAttributes[element] = data[element];
    });
    try {
      const response = await axios.put(
        `${API_SERVER_URL}/api/talent/profile/updateByEntityKey/${profileId}`,
        newProfile,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        localStorage.setItem('talentPageAction', 'update');
        history.push(`/talent/profiles?from=update`);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  }

  useEffect(() => {
    if (isAddMode) {
      const userProfiles = JSON.parse(localStorage.getItem('profiles') || '[]');
      if (userProfiles.length >= parseInt(MAXIMUM_PROFILES_NUMBER, 10)) {
        history.push(`/talent/profiles?from=add`);
      }
      if (userProfiles.length > 0) {
        fetchTalentProfile(userProfiles[0].entityKey, 'add');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddMode]);

  function onSubmit(data) {
    setIsError(false);
    return isAddMode ? addProfile(data) : updateProfile(data);
  }

  const otherLinksCount = watch('numberOfOtherLinks');
  const additionalInfoCount = watch('numberOfAdditionalInfo');

  function otherLinkNumbers() {
    return [...Array(parseInt(otherLinksCount || 0, 10)).keys()];
  }

  function additionalInfoNumbers() {
    return [...Array(parseInt(additionalInfoCount || 0, 10)).keys()];
  }

  useEffect(() => {
    [0, 1, 2].forEach((i) => {
      unregister(`otherLinks.[${i}].linkName`);
      unregister(`otherLinks.[${i}].linkUrl`);
    });
  }, [otherLinksCount, unregister]);

  useEffect(() => {
    [0, 1, 2].forEach((i) => {
      unregister(`additionalInfo.[${i}].infoName`);
      unregister(`additionalInfo.[${i}].infoBody`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInfoCount]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
      {isAddMode && <WelcomeTextForNewAdd />}
      {isError && (
        <small className="mt-3 d-inline-block text-danger">
          Something went wrong. Please try again later.
        </small>
      )}
      <Card className="shadow rounded mt-3 mb-2" border="secondary">
        <Card.Header>
          <h2>
            <i className="bi bi-file-earmark-person-fill mr-1" />
            {isAddMode ? 'Add New Profile' : `Update Profile`}
          </h2>
        </Card.Header>
        <Card.Body>
          <div className="form-group my-3">
            <label htmlFor="profileName">
              Profile Name<abbr className="text-danger">*</abbr>
            </label>
            <input
              name="profileName"
              type="text"
              {...register('profileName')}
              className={`form-control ${
                errors.profileName ? 'is-invalid' : ''
              }`}
            />
            <div className="invalid-feedback">
              {errors.profileName?.message}
            </div>
          </div>

          <div className="form-group my-3">
            <label htmlFor="summary">Summary</label>
            <textarea
              rows="3"
              name="summary"
              type="text"
              {...register('summary')}
              className={`form-control ${errors.summary ? 'is-invalid' : ''}`}
            />
            <div className="invalid-feedback">{errors.summary?.message}</div>
          </div>

          <Button
            variant="link"
            className="text-primary"
            onClick={() => {
              setEnableProfileFields(!enableProfileFields);
            }}
          >
            {enableProfileFields ? (
              <i className="bi bi-file-minus mr-1" />
            ) : (
              <i className="bi bi-file-plus mr-1" />
            )}
            Profile fields
          </Button>

          {enableProfileFields && (
            <div className="shadow rounded pl-4 pr-2 pb-2 bg-light">
              <div className="form-group my-3">
                <label htmlFor="workExperience">Work Experience</label>
                <textarea
                  rows="10"
                  name="workExperience"
                  type="text"
                  {...register('workExperience')}
                  className={`form-control ${
                    errors.workExperience ? 'is-invalid' : ''
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.workExperience?.message}
                </div>
              </div>
              <div className="form-group my-3">
                <label htmlFor="skills">Skills</label>
                <textarea
                  rows="3"
                  name="skills"
                  type="text"
                  {...register('skills')}
                  className={`form-control ${
                    errors.skills ? 'is-invalid' : ''
                  }`}
                />
                <div className="invalid-feedback">{errors.skills?.message}</div>
              </div>
              <div className="form-group my-3">
                <label htmlFor="certifications">Certifications</label>
                <textarea
                  rows="3"
                  name="certifications"
                  type="text"
                  {...register('certifications')}
                  className={`form-control ${
                    errors.certifications ? 'is-invalid' : ''
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.certifications?.message}
                </div>
              </div>
              <div className="form-group my-3">
                <label htmlFor="patents">Patents</label>
                <textarea
                  rows="3"
                  name="patents"
                  type="text"
                  {...register('patents')}
                  className={`form-control ${
                    errors.patents ? 'is-invalid' : ''
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.patents?.message}
                </div>
              </div>
              <div className="form-group my-3">
                <label htmlFor="publications">Publications</label>
                <textarea
                  rows="3"
                  name="publications"
                  type="text"
                  {...register('publications')}
                  className={`form-control ${
                    errors.publications ? 'is-invalid' : ''
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.publications?.message}
                </div>
              </div>
              <div className="form-group my-3">
                <label htmlFor="languages">Languages</label>
                <input
                  name="languages"
                  type="text"
                  {...register('languages')}
                  className={`form-control ${
                    errors.languages ? 'is-invalid' : ''
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.languages?.message}
                </div>
              </div>
              <div className="form-group my-3">
                <label htmlFor="education">Education</label>
                <textarea
                  rows="3"
                  name="education"
                  type="text"
                  {...register('education')}
                  className={`form-control ${
                    errors.education ? 'is-invalid' : ''
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.education?.message}
                </div>
              </div>
            </div>
          )}

          <div className="form-group my-3 ">
            <label
              htmlFor="userInputPersonalWebsiteUrl"
              className="col-form-label"
            >
              <i className="bi bi-linkedin mr-1" />
              LinkedIn Profile Link
            </label>
            <input
              type="text"
              {...register('linkedInLink')}
              className={`form-control ${
                errors.linkedInLink ? 'is-invalid' : ''
              }`}
              id="userInputPersonalWebsiteUrl"
            />
            <div className="invalid-feedback">
              {errors.linkedInLink?.message}
            </div>
          </div>
          <div className="form-group my-3">
            <label
              htmlFor="userInputPersonalResumeUrl"
              className="col-form-label"
            >
              <i className="bi bi-file-earmark-person mr-1" />
              Resume Link
            </label>
            <input
              type="text"
              {...register('resumeLink')}
              className={`form-control ${
                errors.resumeLink ? 'is-invalid' : ''
              }`}
              id="userInputPersonalResumeUrl"
            />
            <div className="invalid-feedback">{errors.resumeLink?.message}</div>
          </div>
          <div className="form-group my-3">
            <label
              htmlFor="userInputPersonalVideoUrl"
              className="col-form-label"
            >
              <i className="bi bi-file-play-fill mr-1" />
              Video Link
            </label>
            <input
              type="text"
              {...register('videoLink')}
              className={`form-control ${errors.videoLink ? 'is-invalid' : ''}`}
              id="userInputPersonalVideoUrl"
            />
            <div className="invalid-feedback">{errors.videoLink?.message}</div>
          </div>
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="form-row">
                <div className="mr-2">
                  <label
                    htmlFor="userInputNumberOfOtherLinks"
                    className="col-form-label text-primary"
                  >
                    <i className="bi bi-paperclip mr-1" />
                    Other links(maximum 3)
                  </label>
                </div>
                <div className="">
                  <select
                    name="numberOfOtherLinks"
                    {...register('numberOfOtherLinks')}
                    className={`form-control ${
                      errors.numberOfOtherLinks ? 'is-invalid' : ''
                    }`}
                  >
                    {['', 1, 2, 3].map((i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {otherLinkNumbers().map((i) => (
            <div
              key={i}
              className="list-group list-group-flush shadow rounded p-4 bg-light"
            >
              <div className="list-group-item">
                <div className="form-row">
                  <div className="form-group col-3">
                    <label>Name</label>
                    <input
                      name={`otherLinks[${i}]linkName`}
                      {...register(`otherLinks.[${i}].linkName`)}
                      type="text"
                      className={`form-control ${
                        errors.otherLinks?.[i]?.linkName ? 'is-invalid' : ''
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.otherLinks?.[i]?.linkName?.message}
                    </div>
                  </div>
                  <div className="form-group col-9">
                    <label>Link</label>
                    <input
                      name={`otherLinks[${i}]linkUrl`}
                      {...register(`otherLinks.[${i}].linkUrl`)}
                      type="text"
                      className={`form-control ${
                        errors.otherLinks?.[i]?.linkUrl ? 'is-invalid' : ''
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.otherLinks?.[i]?.linkUrl?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="form-row">
                <div className="mr-2">
                  <label
                    htmlFor="userInputNumberOfAdditionalInfo"
                    className="col-form-label text-primary"
                  >
                    <i className="bi bi-info-circle mr-1" />
                    Additional Information(maximum 3)
                  </label>
                </div>
                <div className="">
                  <select
                    name="numberOfAdditionalInfo"
                    {...register('numberOfAdditionalInfo')}
                    className={`form-control ${
                      errors.numberOfAdditionalInfo ? 'is-invalid' : ''
                    }`}
                  >
                    {['', 1, 2, 3].map((i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {additionalInfoNumbers().map((i) => (
            <div
              key={i}
              className="list-group list-group-flush shadow rounded p-4 bg-light"
            >
              <div className="list-group-item">
                <div className="form-row">
                  <div className="form-group col-3">
                    <label>Title</label>
                    <input
                      name={`additionalInfo[${i}]infoName`}
                      {...register(`additionalInfo.[${i}].infoName`)}
                      type="text"
                      className={`form-control ${
                        errors.additionalInfo?.[i]?.infoName ? 'is-invalid' : ''
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.additionalInfo?.[i]?.infoName?.message}
                    </div>
                  </div>
                  <div className="form-group col-9">
                    <label>Text</label>
                    <input
                      name={`additionalInfo[${i}]infoBody`}
                      {...register(`additionalInfo.[${i}].infoBody`)}
                      type="text"
                      className={`form-control ${
                        errors.additionalInfo?.[i]?.infoBody ? 'is-invalid' : ''
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.additionalInfo?.[i]?.infoBody?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Card.Body>
        <Card.Footer>
          <div className="form-group">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary px-5"
            >
              {isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1" />
              )}
              {isAddMode ? 'Add' : 'Update'}
            </button>
            <Link to="/talent/profiles" className="btn btn-link ml-3">
              Cancel
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </form>
  );
}

export default TalentProfileAddEdit;
