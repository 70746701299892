import {useState, useEffect} from 'react';
import axios from 'axios';
import {AddPreviewImages} from '../../media/Utils/Helper';

const useMultiKeywordSearch = (keywords, searchUrl, token) => {
  const [resultData, setResultData] = useState([]);
  const [presentations, setPresentations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (keywords && keywords.length > 0) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.post(
            searchUrl,
            {
              searchList: keywords,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const presentationsData = [];
          response.data.forEach((item) => {
            presentationsData.push(
              ...item.presentations.map((presentation) => ({
                ...presentation,
                companyName: item.company?.companyName || 'Company',
              }))
            );
          });
          setResultData(response.data);
          setPresentations(AddPreviewImages(presentationsData));
        } catch (err) {
          if (err.response && err.response.status === 404) {
            setResultData([]);
            setPresentations([]);
          } else {
            setError(err);
          }
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [keywords, searchUrl, token]);

  return {error, loading, presentations, resultData};
};

export default useMultiKeywordSearch;
