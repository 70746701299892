import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function Contact() {
  return (
    <Container>
      <Row className="my-3 text-center">
        <Col>
          <h2 className="my-5">Contact</h2>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <p className="font-weight-bolder">
            <i className="bi bi-telephone mr-2" />
            GET IN TOUCH
          </p>
          <p>+1-415-793-4072</p>
          <p>
            <a href="mailto:support@startnation.live">
              support@startnation.live
            </a>
          </p>
          <p>
            <a href="mailto:sales@startnation.live">sales@startnation.live</a>
          </p>
          <p>
            <a href="mailto:info@startnation.live">info@startnation.live</a>
          </p>
        </Col>
      </Row>
      <Row className="my-5">
        <Col>
          <p className="font-weight-bolder">
            <i className="bi bi-building  mr-2" />
            CORPORATE LOCATION
          </p>
          <p>Livermore, CA 94550</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
