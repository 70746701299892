import React, {useState, useEffect} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import PropTypes from 'prop-types';
import userTypes from '../store/constants';

function ExceedMaximumTalentsAlert() {
  const [show, setShow] = useState(true);
  return (
    show && (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          Number of talent users has exceeded current limit! Please contact
          StartNation for assistance.
        </Alert.Heading>
      </Alert>
    )
  );
}

function ExceedMaximumCompaniesAlert() {
  const [show, setShow] = useState(true);
  return (
    show && (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          Number of startup clients has exceeded current limit! Please contact
          StartNation for assistance.
        </Alert.Heading>
      </Alert>
    )
  );
}

function LimitCountryAlert({country, type}) {
  const [show, setShow] = useState(true);
  return (
    show && (
      <Alert variant="warning" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          Hello, currently we only support {country} for {type} users.
        </Alert.Heading>
      </Alert>
    )
  );
}
LimitCountryAlert.propTypes = {
  country: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SignUp() {
  const query = useQuery();
  const typeFromUrl = query.get('type');
  const referrerTalentUserKeyFromUrl = query.get('referrer_talent_user_key');
  const companyKeyFromUrl = query.get('company_key');
  let defaultAccountType;
  if (typeFromUrl && typeFromUrl === userTypes.COMPANY.value) {
    defaultAccountType = userTypes.COMPANY.value;
  } else if (typeFromUrl && typeFromUrl === userTypes.TALENT.value) {
    defaultAccountType = userTypes.TALENT.value;
  } else if (typeFromUrl && typeFromUrl === userTypes.INVESTOR.value) {
    defaultAccountType = userTypes.INVESTOR.value;
  }

  const [accountType, setAccountType] = useState(defaultAccountType);
  const [showTalentLimitAlert, setShowTalentLimitAlert] = useState(false);
  const [showCompanyLimitAlert, setShowCompanyLimitAlert] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
  const [newAccountRegistered, setNewAccountRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showCountryAlert, setShowCountryAlert] = useState(false);
  const [countryList, setCountryList] = useState('');

  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const ENABLE_INVESTOR_USER =
    process.env.REACT_APP_ENABLE_INVESTOR_USER === 'true';

  const MAX_NUM_OF_TALENTS = process.env.REACT_APP_MAX_NUM_OF_TALENTS
    ? parseInt(process.env.REACT_APP_MAX_NUM_OF_TALENTS, 10)
    : -1;

  const MAX_NUM_OF_COMPANIES = process.env.REACT_APP_MAX_NUM_OF_COMPANIES
    ? parseInt(process.env.REACT_APP_MAX_NUM_OF_COMPANIES, 10)
    : -1;

  const FREE_GEO_IP_URL = process.env.REACT_APP_API_FREE_GEO_IP_URL;
  const LIMIT_TO_COUNTRY_FOR_COMPANY = process.env
    .REACT_APP_LIMIT_TO_COUNTRY_FOR_COMPANY
    ? process.env.REACT_APP_LIMIT_TO_COUNTRY_FOR_COMPANY.split(',')
    : [];
  const LIMIT_TO_COUNTRY_FOR_TALENT = process.env
    .REACT_APP_LIMIT_TO_COUNTRY_FOR_TALENT
    ? process.env.REACT_APP_LIMIT_TO_COUNTRY_FOR_TALENT.split(',')
    : [];

  useEffect(() => {
    async function fetchNumOfTalents() {
      try {
        const fetchAPI = `${API_SERVER_URL}/api/talent/user/public/getCount`;
        const response = await axios.get(fetchAPI);
        if (response.status === 200 && MAX_NUM_OF_TALENTS >= 0) {
          const totalNumTalents = parseInt(response.data.TOTAL_NUM_TALENTS, 10);
          if (totalNumTalents >= MAX_NUM_OF_TALENTS) {
            setShowTalentLimitAlert(true);
          }
        }
      } catch (error) {
        console.log('SignUp fetchNumOfTalents error - ', error);
      }
    }

    async function fetchNumOfCompanies() {
      try {
        const fetchAPI = `${API_SERVER_URL}/api/company/user/public/getCount`;
        const response = await axios.get(fetchAPI);
        if (response.status === 200 && MAX_NUM_OF_COMPANIES >= 0) {
          if (
            parseInt(response.data.TOTAL_NUM_COMPANIES, 10) >=
            MAX_NUM_OF_COMPANIES
          ) {
            setShowCompanyLimitAlert(true);
          }
        }
      } catch (error) {
        console.log('SignUp fetchNumOfCompanies error - ', error);
      }
    }

    if (accountType === userTypes.COMPANY.value) {
      setShowTalentLimitAlert(false);
      fetchNumOfCompanies();
    }
    if (accountType === userTypes.TALENT.value) {
      setShowCompanyLimitAlert(false);
      fetchNumOfTalents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  useEffect(() => {
    async function fetchUserGeoInfo() {
      try {
        const fetchAPI = `${FREE_GEO_IP_URL}`;
        const response = await axios.get(fetchAPI);
        if (response.status === 200) {
          if (accountType === userTypes.TALENT.value) {
            if (
              LIMIT_TO_COUNTRY_FOR_TALENT.length > 0 &&
              !LIMIT_TO_COUNTRY_FOR_TALENT.includes(response.data.country_code)
            ) {
              setShowCountryAlert(true);
              setCountryList(LIMIT_TO_COUNTRY_FOR_TALENT.join('/'));
            }
          } else if (accountType === userTypes.COMPANY.value) {
            if (
              LIMIT_TO_COUNTRY_FOR_COMPANY.length > 0 &&
              !LIMIT_TO_COUNTRY_FOR_COMPANY.includes(response.data.country_code)
            ) {
              setShowCountryAlert(true);
              setCountryList(LIMIT_TO_COUNTRY_FOR_COMPANY.join('/'));
            }
          }
        }
      } catch (error) {
        console.log('SignUp Error Getting user Ip info - ', error);
      }
    }
    fetchUserGeoInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email(),
    newFirstName: yup
      .string()
      .required('First Name is required')
      .matches(/^[A-Za-z]*$/, 'First Name must contain only letters'),
    newLastName: yup
      .string()
      .required('Last Name is required')
      .matches(/^[A-Za-z]*$/, 'Last Name must contain only letters'),
    newPassword: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./:;<=>?[\]^_`{|}~])(?=.{8,})/,
        'Does not meet the password rules!'
      ),
  });

  const {
    formState: {errors},
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const watchEmailAddress = watch('email', '');

  const handleSignUpSubmit = async (data) => {
    // e.preventDefault();
    setLoading(true);
    setIsError(false);
    setIsAlreadyRegistered(false);
    setNewAccountRegistered(false);
    try {
      const user = {
        firstName: data.newFirstName,
        lastName: data.newLastName,
        emailAddress: data.email,
        userPassword: data.newPassword,
        userTypes: accountType,
        isVerified: false,
        currentType: accountType,
      };
      const response = await axios.post(
        `${API_SERVER_URL}/api/user/create`,
        user,
        {
          params: {
            ...(referrerTalentUserKeyFromUrl
              ? {referrer: referrerTalentUserKeyFromUrl}
              : {}),
            ...(companyKeyFromUrl ? {company: companyKeyFromUrl} : {}),
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setNewAccountRegistered(true);
      } else {
        setIsAlreadyRegistered(true);
        setValue('email', '');
        setIsError(true);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setIsAlreadyRegistered(true);
      setValue('email', '');
      setIsError(true);
    }
  };

  return (
    <Container className="">
      <Row>
        <Col xs={6} className="d-none d-sm-block">
          <br />
          <br />
          {defaultAccountType === undefined &&
            typeFromUrl === null &&
            accountType === undefined && (
              <h1 className="mt-5 w-90">
                <span>
                  The place where remote talents and American startup companies
                  join forces to build the future.
                </span>
              </h1>
            )}

          {accountType === userTypes.TALENT.value && (
            <h1 className="mt-5 w-5">
              <span>
                Earn valuable career experience and equity by investing spare
                hours to help build startups aligned with your passions.
              </span>
            </h1>
          )}

          {accountType === userTypes.COMPANY.value && (
            <h1 className="mt-5 w-90">
              <span>
                Build a passionate team of talents with equity to start up your
                business.
              </span>
            </h1>
          )}
        </Col>
        <Col>
          {showTalentLimitAlert && (
            <Row>
              <ExceedMaximumTalentsAlert />
            </Row>
          )}
          {showCompanyLimitAlert && (
            <Row>
              <ExceedMaximumCompaniesAlert />
            </Row>
          )}
          {showCountryAlert && (
            <Row>
              <LimitCountryAlert country={countryList} type={accountType} />
            </Row>
          )}
          <Row>
            <form onSubmit={handleSubmit(handleSignUpSubmit)}>
              <div className="mb-3">
                <h1>Create Account</h1>
                <br />
                {isError && !isAlreadyRegistered && !newAccountRegistered && (
                  <small className="mt-3 d-inline-block text-danger">
                    Something went wrong. Please try again later.
                  </small>
                )}
                {isAlreadyRegistered && (
                  <small className="mt-3 d-inline-block text-danger">
                    Please select a user account type.{' '}
                  </small>
                )}
                {newAccountRegistered && (
                  <small className="mt-3 d-inline-block text-success">
                    Your new <b>{accountType}</b> account has been created for:{' '}
                    <b>{watchEmailAddress}</b>. Please check your email to
                    verify account. You may need to check your spam folder.
                  </small>
                )}
                {newAccountRegistered && (
                  <NavLink to="/signin" className="">
                    <small>Sign in now!</small>
                  </NavLink>
                )}
                <hr />
              </div>
              {!newAccountRegistered && (
                <>
                  <div className="my-4">
                    <label className="form-label">Account Type</label>
                    <br />
                    <div className="form-check form-check-inline mr-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={accountType === userTypes.TALENT.value}
                        onChange={(e) => setAccountType(e.target.value)}
                        name="userTypeOptions"
                        id="userTypeOptions1"
                        value={userTypes.TALENT.value}
                      />
                      <label className="form-check-label">
                        {userTypes.TALENT.desc}
                      </label>
                    </div>
                    <div className="form-check form-check-inline mr-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={accountType === userTypes.COMPANY.value}
                        onChange={(e) => setAccountType(e.target.value)}
                        name="userTypeOptions"
                        id="userTypeOptions3"
                        value={userTypes.COMPANY.value}
                      />
                      <label className="form-check-label">
                        {userTypes.COMPANY.desc}
                      </label>
                    </div>
                    {ENABLE_INVESTOR_USER && (
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={accountType === userTypes.INVESTOR.value}
                          onChange={(e) => setAccountType(e.target.value)}
                          name="userTypeOptions"
                          id="userTypeOptions2"
                          value={userTypes.INVESTOR.value}
                        />
                        <label className="form-check-label">
                          {userTypes.INVESTOR.desc}
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="my-4">
                    <label className="form-label">E-mail</label>
                    <input
                      type="text"
                      {...register('email')}
                      className={`form-control ${
                        errors.email ? 'is-invalid' : ''
                      }`}
                      disabled={
                        loading ||
                        showTalentLimitAlert ||
                        showCompanyLimitAlert ||
                        showCountryAlert
                      }
                      id="userInputEmail"
                      aria-describedby="userInputEmailHelp"
                    />
                    <div className="invalid-feedback">
                      {errors.email?.message}
                    </div>
                  </div>
                  <div className="my-4">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      {...register('newFirstName')}
                      className={`form-control ${
                        errors.newFirstName ? 'is-invalid' : ''
                      }`}
                      disabled={
                        loading ||
                        showTalentLimitAlert ||
                        showCompanyLimitAlert ||
                        showCountryAlert
                      }
                      id="userInputFirstName"
                      aria-describedby="firstNameHelp"
                    />
                    <div className="invalid-feedback">
                      {errors.newFirstName?.message}
                    </div>
                  </div>
                  <div className="my-4">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      {...register('newLastName')}
                      className={`form-control ${
                        errors.newLastName ? 'is-invalid' : ''
                      }`}
                      disabled={
                        loading ||
                        showTalentLimitAlert ||
                        showCompanyLimitAlert ||
                        showCountryAlert
                      }
                      id="userInputLastName"
                      aria-describedby="lastNameHelp"
                    />
                    <div className="invalid-feedback">
                      {errors.newLastName?.message}
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      {...register('newPassword')}
                      className={`form-control ${
                        errors.newPassword ? 'is-invalid' : ''
                      }`}
                      disabled={
                        loading ||
                        showTalentLimitAlert ||
                        showCompanyLimitAlert ||
                        showCountryAlert
                      }
                      id="userInputNewPassword"
                    />
                    <div className="invalid-feedback">
                      {errors.newPassword?.message}
                    </div>
                    <div id="passwordHelp" className="form-text">
                      <small>
                        (Minimum 8 characters and contain at least one upper
                        case letter, one lower case letter, one number, and one
                        special character)
                      </small>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      disabled={
                        loading ||
                        showTalentLimitAlert ||
                        showCompanyLimitAlert ||
                        showCountryAlert
                      }
                      className="px-5 px-5 btn btn-success rounded-pill"
                    >
                      {loading ? 'Please waiting...' : 'Sign Up'}
                    </button>
                  </div>
                  <div className="mb-3">
                    <small>
                      By clicking on &quot;Sign Up&quot;, you agree to{' '}
                      <NavLink to="/startout/terms" target="_blank">
                        user agreement
                      </NavLink>
                      .
                    </small>
                  </div>
                  <div className="mb-5">
                    <small>Already have an account?</small>
                    <NavLink to="/signin" className="ml-3">
                      <small>Sign in here</small>
                    </NavLink>
                  </div>
                </>
              )}
            </form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SignUp;
