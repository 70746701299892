import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, Alert} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {userSettings} from '../store/atoms';

function AlertDismissibleText({companyName}) {
  const dot = companyName && companyName[companyName.length - 1] === '.' ? '' : '.';
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>User Info</Alert.Heading>
        <p>
          Please provide more info about yourself as a member of <span>{companyName}</span>{dot}
        </p>
      </Alert>
    );
  }
  return null;
}

function CompanyUser() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const history = useHistory();
  const userInfo = useRecoilValue(userSettings);
  const [companyUserExist, setCompanyUserExist] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [equiconWaitlist, setEquiconWaitlist] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isTagShow, setIsTagShow] = useState(true);

  const validationSchema = yup.object().shape({
    title: yup.string().required('Job Title is required'),
    email: yup.string().required('Work Email is required').email(),
  });

  const RoleTypes = {
    CEO: 'Chief Executive Officer',
    CFO: 'Chief Financial Officer',
    President: 'President',
  };

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    async function fetchCompanyUser() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/user/findByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          const {companyKey} = response.data;
          setValue('title', response.data.companyUserAttributes.title);
          setValue('description', response.data.companyUserAttributes.description);
          setValue('email', response.data.companyUserAttributes.email);
          setPhoneNumber(response.data.companyUserAttributes.phoneNumber);
          setEquiconWaitlist(response.data.companyUserAttributes.equicon_waitlist);
          setCompanyUserExist(true);
          setIsError(false);
          try {
            const response2 = await axios.get(
              `${API_SERVER_URL}/api/company/findByEntityKey/${companyKey}`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (response2.status === 200) {
              setCompanyInfo(response2.data);
            }
          } catch (error) {
            history.push(`/company/create/info`);
          }
        }
      } catch (error) {
        setCompanyUserExist(false);
      }
    }

    if (userInfo.userKey) {
      fetchCompanyUser();
    } else {
      history.push(`/company/create/info`);
    }
  }, [userInfo.userKey, history, setValue, API_SERVER_URL, userInfo.token]);

  const createCompanyUser = async (data) => {
    const companyUser = {
      userKey: userInfo.userKey,
      companyKey: userInfo.companyKey,
      companyUserAttributes: {
        title: data.title,
        description: data.description,
        email: data.email,
        phoneNumber,
        equicon_waitlist: false,
      },
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/company/user/create`,
        companyUser,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
        setCompanyUserExist(true);
      }
    } catch (error) {
      setIsSuccess(false);
    }
  };

  const updateCompanyUser = async (data) => {
    const companyUser = {
      companyUserAttributes: {
        title: data.title,
        description: data.description,
        email: data.email,
        phoneNumber,
        equicon_waitlist: equiconWaitlist,
      },
    };
    try {
      const response = await axios.put(
        `${API_SERVER_URL}/api/company/user/updateByUserKey/${userInfo.userKey}`,
        companyUser,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
        // Update state with new values
        setValue('title', data.title);
        setValue('description', data.description);
        setValue('email', data.email);
        setPhoneNumber(data.phoneNumber);
      }
    } catch (error) {
      setIsSuccess(false);
    }
  };

  const onSubmit = async (data) => {
    setIsError(false);
    setIsSuccess(false);
    setIsTagShow(false);

    try {
      if (companyUserExist) {
        await updateCompanyUser(data);
      } else {
        await createCompanyUser(data);
      }
    } catch (error) {
      setIsError(true);
    }

    window.location.reload();
  };

  return (
    <Container className="shadow rounded">
      <Row>
        <Col>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isTagShow && <AlertDismissibleText companyName={companyInfo.companyName} />}
            <div className="my-4">
              {isError && !isSuccess && (
                <small className="mt-3 d-inline-block text-danger">
                  Something went wrong. Please try again later.
                </small>
              )}
              {isSuccess && (
                <small className="mt-3 d-inline-block text-success">
                  Submit success!
                </small>
              )}
            </div>
            <div className="my-4">
              <label className="form-label">
                Your Role<abbr className="text-danger">*</abbr>
              </label>
              <select {...register('title')} className={`form-control ${errors.title ? 'is-invalid' : ''}`} id="userInputJobTitle">
                <option value="">Please choose your role...</option>
                {Object.entries(RoleTypes).map(([key, value]) => (
                  <option key={key} value={value}>{value}</option>
                ))}
              </select>
              <div className="invalid-feedback">{errors.title?.message}</div>
            </div>
            <div className="my-4">
              <label className="form-label">Your Work Email<abbr className="text-danger">*</abbr></label>
              <input type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="userInputEmail" />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="my-4 pt-3">
              <label className="form-label">Your Work Phone<abbr className="text-danger">*</abbr></label>
              <br />
              <PhoneInput country="us" value={phoneNumber} onChange={setPhoneNumber} />
            </div>
            <div className="mb-3">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary px-5">
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1" />} Save
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

CompanyUser.propTypes = {
  history: PropTypes.object.isRequired,
};

AlertDismissibleText.propTypes = {
  companyName: PropTypes.string.isRequired,
};

export default CompanyUser;
