import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col, Modal} from 'react-bootstrap';
import TalentProfileDetail from '../talent/TalentProfileDetail';

function ApplicantInformationModal({
  applicant,
  onHideApplyModal,
  showApplyModal,
}) {
  const appliedStatus = applicant.appliedJobAttributes?.status === 'applied';
  if (!appliedStatus) return null;

  const applicantProfile = {
    profileName: applicant.appliedJobAttributes.profileName,
    profileAttributes: {
      isPublic: false,
      summary: applicant.appliedJobAttributes?.profileAttributes__summary,
      workExperience:
        applicant.appliedJobAttributes?.profileAttributes__workExperience,
      skills: applicant.appliedJobAttributes?.profileAttributes__skills,
      certifications:
        applicant.appliedJobAttributes?.profileAttributes__certifications,
      patents: applicant.appliedJobAttributes?.profileAttributes__patents,
      publications:
        applicant.appliedJobAttributes?.profileAttributes__publications,
      languages: applicant.appliedJobAttributes?.profileAttributes__languages,
      education: applicant.appliedJobAttributes?.profileAttributes__education,
      linkedInLink:
        applicant.appliedJobAttributes?.profileAttributes__linkedInLink,
      resumeLink: applicant.appliedJobAttributes?.profileAttributes__resumeLink,
      videoLink: applicant.appliedJobAttributes?.profileAttributes__videoLink,
      otherLinks: applicant.appliedJobAttributes?.profileAttributes__otherLinks,
      additionalInfo:
        applicant.appliedJobAttributes?.profileAttributes__additionalInfo,
    },
  };

  return (
    <form>
      <Modal
        centered
        size="lg"
        show={showApplyModal}
        onHide={onHideApplyModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Apply - {applicant?.appliedJobAttributes?.jobTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className="shadow rounded">
                <div className="form-group my-4">
                  <label htmlFor="staticApplyTime" className="col-form-label">
                    <i className="bi bi-calendar3 mr-1" />
                    Apply Time
                  </label>
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext"
                    id="staticApplyTime"
                    value={new Date(applicant.updatedTimestamp).toString()}
                  />
                </div>
                <div className="form-group my-4">
                  <label
                    htmlFor="staticApplyUserName"
                    className="col-form-label"
                  >
                    <i className="bi bi-person-fill mr-1" />
                    Applicant Name
                    <abbr className="text-danger">*</abbr>
                  </label>
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext"
                    id="staticApplyUserName"
                    value={applicant?.appliedJobAttributes?.userName}
                  />
                </div>
                <div className="form-group my-4">
                  <label
                    htmlFor="staticApplyUserEmail"
                    className="col-form-label"
                  >
                    <i className="bi bi-envelope-fill mr-1" />
                    Applicant Email
                    <abbr className="text-danger">*</abbr>
                  </label>
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext"
                    id="staticApplyUserEmail"
                    value={applicant?.appliedJobAttributes?.userEmail}
                  />
                </div>
                <div className="form-group my-4">
                  <label
                    htmlFor="staticApplyAdditional"
                    className="col-form-label"
                  >
                    <i className="bi bi-info-square-fill mr-1" />
                    Additional information for recruiter
                  </label>
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext"
                    id="staticApplyAdditional"
                    value={applicant?.appliedJobAttributes?.additional}
                  />
                </div>
              </Col>
            </Row>

            <Row className="pt-5">
              <Col>
                <p>Applicant Profile</p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col md={12} className="shadow rounded">
                <TalentProfileDetail profile={applicantProfile} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <fieldset className="w-100">
            <button
              type="button"
              className="mx-2 btn btn-outline-secondary px-5 my-3 rounded-pill float-right "
              onClick={onHideApplyModal}
            >
              Close
            </button>
          </fieldset>
        </Modal.Footer>
      </Modal>
    </form>
  );
}

ApplicantInformationModal.propTypes = {
  applicant: PropTypes.object.isRequired,
  showApplyModal: PropTypes.bool.isRequired,
  onHideApplyModal: PropTypes.func.isRequired,
};

export default ApplicantInformationModal;
