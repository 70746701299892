import React from 'react';

function Team() {
  return (
    <div className="container">
      <div className="row my-5">
        <div className="col">
          <h1 className="display-4 text-center">Team</h1>
        </div>
      </div>
      <div className="row my-5">
        <div className="col">
          <blockquote className="blockquote">
            <p className="mb-0">
              We founded StartNation based on the joys and pains in building
              startups and leverage equity to match the needs of startup
              businesses with opportunities for talents to enrich their career
              through the StartNation platform. Our engineering and product
              teams consist of both veteran and young professionals who strongly
              believe in the values of working with your passions and learning
              through doing and are on a mission to promote global remote
              productivity and AI advancements to produce world-changing
              technology and product innovations. Our company is led by an
              experienced management team working with a dedicated group of
              talents in front-end and backend software engineering, UI/UX
              design, automation testing, and product management and take great
              pride in our work.
            </p>
          </blockquote>
        </div>
      </div>
      <div className="row my-5" />
    </div>
  );
}

export default Team;
