import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {Container, Row, Col, Form} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import fp from 'lodash/fp';
import industriesList from '../store/industriesList';
import timeZoneList from '../store/timezones';
import {userSettings} from '../store/atoms';
import FindTalentReferrer from '../common/utils/FindTalentReferrer';

function TalentInfo() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [talentUserExist, setTalentUserExist] = useState(false);
  const [eligibility, setEligibility] = useState('');
  const [eligibilityOther, setEligibilityOther] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [industries, setIndustries] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherIndustries, setOtherIndustries] = useState('');
  const history = useHistory();
  const eligibilityValues = ['AuthorizedWork', 'AuthorizedTraining'];
  const eligibilityContents = {
    AuthorizedWork: 'Authorized to work in the United States or Canada',
    AuthorizedTraining:
      'Authorized for practical training as student in the United States or Canada',
    Other: 'Other',
  };
  const animatedComponents = makeAnimated();

  const emptyList = [{value: 'Any', label: 'Any'}];

  useEffect(() => {
    async function fetchTalentInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/user/findByUserKey/${userInfo.userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setEligibility(response.data.talentAttributes.eligibility);
          if (
            !eligibilityValues.includes(
              response.data.talentAttributes.eligibility
            )
          ) {
            setEligibilityOther(response.data.talentAttributes.eligibility);
          }
          setTimeZone(response.data.talentAttributes.timeZone);
          setPhoneNumber(response.data.talentAttributes.phoneNumber);
          let tempOptions = [{value: 'Any', label: 'Any'}];
          if (response.data.talentAttributes.industries !== undefined) {
            if (response.data.talentAttributes.industries !== '') {
              const arr = response.data.talentAttributes.industries.split('^');
              if (
                (arr.length === 2 && arr[0] !== 'Other') ||
                (arr.length > 0 && arr[arr.length - 2] !== 'Other')
              ) {
                tempOptions = fp.map((el) => ({
                  value: el,
                  label: el,
                }))(arr);
              } else {
                const otherContent = arr.pop();
                setOtherIndustries(otherContent);
                tempOptions = fp.map((el) => ({
                  value: el,
                  label: el,
                }))(arr);
              }
            }
          }
          setIndustries(tempOptions);
          setTalentUserExist(true);
          setIsError(false);
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchTalentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTalentInfo = async () => {
    let tempArr = fp.map((el) => el.value)(industries);
    if (otherIndustries !== '') {
      tempArr = tempArr.concat(otherIndustries);
    }
    const talentInfo = {
      talentAttributes: {
        eligibility,
        timeZone,
        phoneNumber,
        industries: tempArr.join('^'),
      },
    };
    if (
      !eligibilityValues.includes(talentInfo.talentAttributes.eligibility) &&
      eligibilityOther
    ) {
      talentInfo.talentAttributes.eligibility = eligibilityOther.trim();
    }
    try {
      const response = await axios.put(
        `${API_SERVER_URL}/api/talent/user/updateByUserKey/${userInfo.userKey}`,
        talentInfo,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsSuccess(false);
    }
    setLoading(false);
  };

  const createTalentInfo = async () => {
    let tempArr = fp.map((el) => el.value)(industries);
    if (otherIndustries !== '') {
      tempArr = tempArr.concat(otherIndustries);
    }
    const talentInfo = {
      userKey: userInfo.userKey,
      talentAttributes: {
        eligibility,
        timeZone,
        phoneNumber,
        industries: tempArr.join('^'),
      },
    };
    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/talent/user/create`,
        talentInfo,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        setIsSuccess(true);
        FindTalentReferrer(
          userInfo.userKey,
          userInfo.token,
          talentUserExist,
          history
        );
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsSuccess(false);
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setIsError(false);
    setIsSuccess(false);
    return talentUserExist ? updateTalentInfo() : createTalentInfo();
  };

  useEffect(() => {
    const list = fp.filter((el) => el.value === 'Other')(industries);
    if (list.length !== 0) {
      setShowOtherInput(true);
    } else {
      setOtherIndustries('');
      setShowOtherInput(false);
    }
  }, [industries, setShowOtherInput]);

  return (
    <Container className="">
      <Row>
        <Col>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <h2>Talent Info</h2>
              {/* <p>{eligibility} / {phoneNumber} / {timeZone} </p> */}
              <br />
              {isError && !isSuccess && (
                <small className="mt-3 d-inline-block text-danger">
                  {/* Something went wrong. Please try again later. */}
                  please fill all the required filed
                </small>
              )}
              {isSuccess && (
                <small className="mt-3 d-inline-block text-success">
                  Submit success!
                </small>
              )}
              <hr />
            </div>
            <div className="my-4 pt-3">
              <label className="form-label">
                <span className="text-danger">*</span> Phone Number
              </label>
              <br />
              <PhoneInput
                country="us"
                value={phoneNumber}
                inputProps={{
                  required: true,
                  autoFocus: true,
                }}
                onChange={(phone) => setPhoneNumber(phone)}
              />
              {!phoneNumber && (
                <small className="text-danger">Phone number is required.</small>
              )}
            </div>
            <div className="my-4 pt-3">
              <label className="form-label">
                <span className="text-danger">*</span> Time Zone
              </label>
              <br />
              <Form.Control
                as="select"
                value={timeZone}
                inputProps={{
                  required: true,
                  autoFocus: true,
                }}
                onChange={(e) => setTimeZone(e.target.value)}
                required
              >
                <option value="">Please choose timezone...</option>
                {Object.entries(timeZoneList).map(([key, value]) => (
                  <option key={key} value={key}>
                    {key} - {value}
                  </option>
                ))}
              </Form.Control>
              {!timeZone && (
                <small className="text-danger">Time zone is required.</small>
              )}
            </div>
            <div className="my-4 pt-3">
              <label className="form-label">Industry</label>
              <br />
              <Select
                closeMenuOnSelect
                components={animatedComponents}
                value={industries}
                isMulti
                options={
                  industries.length === 1 && industries[0].value === 'Any'
                    ? emptyList
                    : industriesList
                }
                onChange={(e) => {
                  if (fp.find((el) => el.value === 'Any')(e) !== undefined) {
                    setIndustries([{value: 'Any', label: 'Any'}]);
                  } else {
                    setIndustries(e);
                  }
                }}
              />

              {showOtherInput && (
                <>
                  <br />
                  <span className="mr-4" />
                  <label className="form-label">Other</label>
                  <div className="form-check mb-2">
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Please specify industries separated by ,"
                      id="otherIndustries"
                      value={otherIndustries}
                      onChange={(e) => setOtherIndustries(e.target.value)}
                      required
                    />
                  </div>
                </>
              )}
            </div>
            <div className="my-4">
              <label className="form-label">
                <span className="text-danger">*</span> Eligibility
              </label>
              <br />
              <input
                type="radio"
                name="eligibilityOptions"
                value={eligibility}
                className="d-none"
                required
              />
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={eligibility === 'AuthorizedWork'}
                  onChange={(e) => setEligibility(e.target.value)}
                  name="eligibilityOptions"
                  id="eligibilityOptions1"
                  value="AuthorizedWork"
                />
                <label className="form-check-label">
                  {eligibilityContents.AuthorizedWork}
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={eligibility === 'AuthorizedTraining'}
                  onChange={(e) => setEligibility(e.target.value)}
                  name="eligibilityOptions"
                  id="eligibilityOptions2"
                  value="AuthorizedTraining"
                />
                <label className="form-check-label">
                  {eligibilityContents.AuthorizedTraining}
                </label>
              </div>
              <div className="form-check mb-2">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={
                    eligibility !== '' &&
                    !eligibilityValues.includes(eligibility)
                  }
                  name="eligibilityOptions"
                  id="eligibilityOptions4"
                  onChange={(e) => setEligibility(e.target.value)}
                  value="Other"
                />
                <label className="form-check-label">
                  {eligibilityContents.Other}
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder="If other, please specify"
                  id="userInputOtherEligibility"
                  disabled={eligibilityValues.includes(eligibility)}
                  aria-describedby="userInputOtherEligibilityHelp"
                  value={eligibilityOther}
                  onChange={(e) => setEligibilityOther(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                disabled={loading}
                className="px-5 px-5 btn btn-success rounded-pill"
              >
                {loading ? 'Please waiting...' : 'Update'}
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default TalentInfo;
