/* eslint-disable react/prop-types */
import React, {useState, useMemo, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {Container, Row, Col, Navbar, Nav, NavDropdown} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import userTypes from '../store/constants';
import LogoImage from './LogoImage';
import {userSettings, newFeedbackInfoState} from '../store/atoms';
import {FeedbackModal} from '../common/feedback/FeedbackModal';
import SystemAlert from '../common/SystemAlert';

function CompanyLayoutHeader() {
  const authenticated = localStorage.getItem('authenticated');
  const loggedInAsUserType = localStorage.getItem('loggedInAsUserType');
  const [messageModal, setMessageModel] = useState(false);
  const [isFeedbackReceiver, setIsFeedbackReceiver] = useState(false);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  let companyHasThePermission = false;
  const systemAlertInfo = useRecoilValue(newFeedbackInfoState);
  const [defaultPresent, setDefaultPresent] = useState(undefined);
  const [isDisableHeaderMenu, setIsDisableHeaderMenu] = useState(true);

  const isCompanyUserInfoExist = (companyUserAttributes) =>
    companyUserAttributes &&
    typeof companyUserAttributes === 'object' &&
    Object.keys(companyUserAttributes).length > 0 &&
    Object.values(companyUserAttributes).every(
      (value) => typeof value === 'string' && value.trim() !== ''
    );

  const isCompanyInfoExist = (companyAttributes) => {
    if (!companyAttributes) return false;

    const requiredFields = [
      'country',
      'stateOfRegistration',
      'name',
      'operationalStatus',
      'EIN',
      'phoneNumber',
      'email',
      'city',
      'state',
    ];

    return requiredFields.every(
      (field) => companyAttributes[field]?.trim() !== ''
    );
  };

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/presentation/findByCompanyKey/${userInfo.companyKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setDefaultPresent(
            response.data[0].presentationAttributes.lastSaved
              ? response.data[0].presentationAttributes.lastSaved
              : 'unsaved'
          );
        }
        console.log(
          'response.data[0].presentationAttributes',
          response.data[0].presentationAttributes
        );
      } catch (error) {
        console.error(
          'CompanyPresentationUpdate found Presentation error - ',
          error
        );
      }
    }

    async function fetchCompanyInfo() {
      try {
        const findCompanyUserResponse = await axios.get(
          `${API_SERVER_URL}/api/company/user/findByUserKey/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (findCompanyUserResponse.status === 200) {
          const {companyKey, companyUserAttributes} =
            findCompanyUserResponse.data;
          if (companyKey) {
            try {
              const findCompanyResponse = await axios.get(
                `${API_SERVER_URL}/api/company/findByEntityKey/${companyKey}`,
                {headers: {Authorization: `Bearer ${userInfo.token}`}}
              );
              if (findCompanyResponse.status === 200) {
                console.log('Find company user');
                if (
                  isCompanyUserInfoExist(companyUserAttributes) &&
                  isCompanyInfoExist(
                    findCompanyResponse?.data?.companyAttributes
                  )
                ) {
                  setIsDisableHeaderMenu(false);
                } else {
                  setIsDisableHeaderMenu(true);
                }
              }
            } catch (error) {
              console.log('/api/company/findByEntityKey failed - ', error);
            }
          } else {
            console.log('api/company/user/findByUserKey failed');
          }
        }
      } catch (error) {
        console.log('fetchCompanyInfo failed - ', error);
      }
    }
    if (userInfo.userTypes === 'COMPANY') {
      fetchCompanyInfo();
    }
    fetchCompanyPresentation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchFeedbackUserKey() {
    const FEEDBACK_USER_EMAIL = 'feedback@startnation.live';

    try {
      const response = await axios.get(
        `${API_SERVER_URL}/api/user/findByEmail/${FEEDBACK_USER_EMAIL}`,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        if (response.data.userKey === userInfo.userKey) {
          setIsFeedbackReceiver(true);
        }
      }
    } catch (error) {
      console.error('feedback user not found - ', error);
    }
  }

  const feedbackReceiverChecker = useMemo(() => {
    if (authenticated && loggedInAsUserType === userTypes.COMPANY.value) {
      fetchFeedbackUserKey();
    }
    // eslint-disable-next-line
  }, [authenticated, loggedInAsUserType, userTypes, fetchFeedbackUserKey]);

  if (authenticated && loggedInAsUserType === userTypes.COMPANY.value) {
    companyHasThePermission = true;
  }
  if (!companyHasThePermission) {
    return <Redirect to="/" />;
  }

  const handleLogout = () => {
    localStorage.removeItem('authenticated');
    localStorage.removeItem('entityKey');
    localStorage.removeItem('userKey');
    localStorage.removeItem('companyKey');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('userTypes');
    localStorage.removeItem('loggedInAsUserType');
    localStorage.removeItem('emailAddress');
    localStorage.removeItem('token');
    window.location = '/';
  };

  return (
    <Container fluid>
      <Row>
        <Col xl={{span: 10, offset: 1}}>
          <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand href="/">
              <LogoImage />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <NavDropdown
                  disabled={isDisableHeaderMenu}
                  title="Present"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="/company/presentation/update">
                    <i className="bi mr-1" />
                    Use Your Video
                    {defaultPresent === 'clientIntro' ? (
                      <span
                        className="small"
                        style={{
                          marginLeft: '8px',
                          color: 'grey',
                          fontSize: '10px',
                          fontStyle: 'italic',
                        }}
                      >
                        Live
                      </span>
                    ) : null}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/company/presentation/update_instaIntro">
                    <i className="bi mr-1" />
                    Use StartMedia &trade; Image
                    {defaultPresent === 'startIntro' ? (
                      <span
                        className="small"
                        style={{
                          marginLeft: '8px',
                          color: 'grey',
                          fontSize: '10px',
                          fontStyle: 'italic',
                        }}
                      >
                        Live
                      </span>
                    ) : null}
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  disabled={isDisableHeaderMenu}
                  href="/company/talents/find"
                >
                  Hire
                </Nav.Link>
                <Nav.Link href="/company/create/info">Company</Nav.Link>
                {/* {isCompanyInfoExist && (
                  <Nav.Link href="/company/equicoin">Equicoin</Nav.Link>
                )} */}
              </Nav>
              <Nav>
                <NavDropdown
                  title={<i className="bi bi-person-fill" />}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="/company/account">
                    <i className="bi bi-person-fill mr-1" />
                    Account Info
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/company/user">
                    <i className="bi bi-file-earmark-person-fill mr-1" />
                    User Info
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>
                    <i className="bi bi-box-arrow-right mr-1" />
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  disabled={isDisableHeaderMenu}
                  title={<i className="bi bi-envelope-fill" />}
                  id="nav-dropdown"
                >
                  <NavDropdown.Item href="/company/message/inbox">
                    Inbox
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/company/message/sent">
                    Sent
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/company/message/draft">
                    Draft
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href="#"
                  onClick={() => setMessageModel(true)}
                  style={isDisableHeaderMenu ? {} : {color: 'grey'}}
                  disabled={isDisableHeaderMenu}
                >
                  <i className="bi bi-pencil-square" />
                  User Feedback
                </Nav.Link>
                <NavDropdown
                  id="nav-dropdown"
                  style={{margin: '0px 0px 0px -12px'}}
                  disabled={isDisableHeaderMenu}
                >
                  <NavDropdown.Item href="/company/user-feedback/inbox">
                    Inbox
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/company/user-feedback/sent">
                    Sent
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/company/user-feedback/draft">
                    Draft
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {messageModal && !isFeedbackReceiver && (
            <FeedbackModal
              showModal={messageModal}
              setShowModal={setMessageModel}
              feedbackReceiverChecker={feedbackReceiverChecker}
            />
          )}
          <hr />
        </Col>

        {systemAlertInfo.show && (
          <Col>
            <SystemAlert info={systemAlertInfo} />
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default CompanyLayoutHeader;
