import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Alert, ButtonGroup} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import fp from 'lodash/fp';
import {userSettings} from '../../store/atoms';

export function FeedbackModal({setShowModal, showModal}) {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const handleClose = () => setShowModal(false);
  const [subject, setSubject] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [isClearDisabled, setIsClearDisabled] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [entityKey, setEntityKey] = useState('');
  const [existDraft, setExistDraft] = useState(false);
  const [feedbackUserKey, setFeedbackUserKey] = useState('');
  const [feedbackKey, setFeedbackKey] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [alert, setAlert] = useState({show: false, variant: '', message: ''});

  useEffect(() => {
    async function fetchDraftFeedback() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/findByUnsent/${
            userInfo.userKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          const draftMessage = fp.filter(
            (el) => el.fromUserKey === userInfo.userKey
          )(response.data);
          if (draftMessage.length !== 0) {
            setSubject(draftMessage[0]?.sentFeedbackAttributes?.subject);
            setMessageContent(draftMessage[0]?.sentFeedbackAttributes?.body);
            setEntityKey(draftMessage[0]?.entityKey);
            setExistDraft(true);
            setFeedbackKey(
              draftMessage[0]?.sentFeedbackAttributes?.feedbackKey +
                userInfo.userKey
            );
          }
        }
      } catch (error) {
        console.error('Constructor Failed to get draft feedback - ', error);
      }
    }

    async function fetchFeedbackUserKey() {
      const FEEDBACK_USER_EMAIL = 'feedback@startnation.live';
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/user/findByEmail/${FEEDBACK_USER_EMAIL}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setFeedbackUserKey(response.data.userKey);
        }
      } catch (error) {
        console.error('feedback user not found - ', error);
      }
    }

    async function fetchCompanyName() {
      if (userInfo.loggedInAsUserType === 'COMPANY' && userInfo.companyKey) {
        try {
          const findCompanyResponse = await axios.get(
            `${API_SERVER_URL}/api/company/findByEntityKey/${userInfo.companyKey}`,
            {headers: {Authorization: `Bearer ${userInfo.token}`}}
          );
          if (findCompanyResponse.status === 200) {
            setCompanyName(findCompanyResponse.data.companyName);
          }
        } catch (error) {
          console.error('Feedback cannot fetch company name - ', error);
        }
      }
    }

    if (userInfo.userKey) {
      fetchDraftFeedback();
      fetchFeedbackUserKey();
      fetchCompanyName();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subject && messageContent) {
      setIsClearDisabled(false);
      setIsSaveDisabled(false);
      setIsSendDisabled(false);
    } else if (subject || messageContent) {
      setIsClearDisabled(false);
      setIsSaveDisabled(false);
      setIsSendDisabled(true);
    } else {
      setIsSaveDisabled(true);
      setIsSendDisabled(true);
      setIsClearDisabled(true);
    }
  }, [subject, messageContent]);

  const handleSave = () => {
    async function saveDraft() {
      try {
        const request = {
          fromUserKey: userInfo.userKey,
          toUserKey: feedbackUserKey,
          toUserType: userInfo.loggedInAsUserType,
          sentFeedbackAttributes: {
            senderRole: userInfo.userTypes,
            feedbackKey: new Date() + userInfo.userKey,
            subject,
            body: messageContent,
            modifyTime: new Date(),
            userType: userInfo.loggedInAsUserType,
            userName:
              userInfo.loggedInAsUserType === 'COMPANY'
                ? companyName
                : `${userInfo.firstName} ${userInfo.lastName}`,
            userEmail: userInfo.emailAddress,
            originMsgSub: subject,
            originMsgBody: messageContent,
          },
        };
        const response = await axios.post(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/create`,
          request,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setEntityKey(response.data.entityKey);
          setExistDraft(true);
          setAlert({
            show: true,
            variant: 'success',
            message: 'Feedback draft saved successfully!',
          });
        }
      } catch (error) {
        console.error('Failed to get unsent feedback - ', error);
        setAlert({
          show: true,
          variant: 'danger',
          message: 'Failed to save feedback draft, please try again!',
        });
      }
    }

    async function updateDraft() {
      try {
        const request = {
          fromUserKey: userInfo.userKey,
          toUserKey: feedbackUserKey,
          toUserType: userInfo.loggedInAsUserType,
          sentFeedbackAttributes: {
            senderRole: userInfo.userTypes,
            feedbackKey,
            subject,
            body: messageContent,
            modifyTime: new Date(),
            userType: userInfo.loggedInAsUserType,
            userName:
              userInfo.loggedInAsUserType === 'COMPANY'
                ? companyName
                : `${userInfo.firstName} ${userInfo.lastName}`,
            userEmail: userInfo.emailAddress,
            originMsgSub: subject,
            originMsgBody: messageContent,
          },
        };
        const response = await axios.put(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/updateByEntityKey/${entityKey}`,
          request,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setAlert({
            show: true,
            variant: 'success',
            message: 'Feedback draft updated successfully!',
          });
        }
      } catch (error) {
        console.error('Failed to save draft feedback - ', error);
        setAlert({
          show: true,
          variant: 'danger',
          message: 'Failed to save feedback draft, please try again!',
        });
      }
    }

    if (userInfo.userKey) {
      if (existDraft) {
        updateDraft();
      } else {
        saveDraft();
      }
    }
  };

  const handleSend = () => {
    async function sendWithDraft() {
      try {
        const sendResponse = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/sendByEntityKey/${entityKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (sendResponse.status === 200) {
          console.log('Sent feedback - ', sendResponse.data.length);
          setAlert({
            show: true,
            variant: 'success',
            message: 'Feedback sent successfully!',
          });
        }
      } catch (error) {
        console.error('Failed to get sent feedback - ', error);
        setAlert({
          show: true,
          variant: 'danger',
          message: 'Failed to send feedback, please try again!',
        });
      }
    }

    async function sendWithoutDraft() {
      try {
        const request = {
          fromUserKey: userInfo.userKey,
          toUserKey: feedbackUserKey,
          toUserType: userInfo.loggedInAsUserType,
          sentFeedbackAttributes: {
            senderRole: userInfo.userTypes,
            feedbackKey: new Date() + userInfo.userKey,
            subject,
            body: messageContent,
            modifyTime: new Date(),
            userType: userInfo.loggedInAsUserType,
            userName:
              userInfo.loggedInAsUserType === 'COMPANY'
                ? companyName
                : `${userInfo.firstName} ${userInfo.lastName}`,
            userEmail: userInfo.emailAddress,
            originMsgSub: subject,
            originMsgBody: messageContent,
          },
        };
        const response = await axios.post(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/create`,
          request,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          try {
            const sendResponse = await axios.get(
              `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/feedback/send/sendByEntityKey/${
                response.data.entityKey
              }`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (sendResponse.status === 200) {
              setAlert({
                show: true,
                variant: 'success',
                message: 'Feedback sent successfully!',
              });
            }
          } catch (error) {
            setAlert({
              show: 'true',
              status: 'danger',
              message: 'Sent feedback failed, please try again!',
            });
          }
        }
      } catch (error) {
        setAlert({
          show: 'true',
          status: 'danger',
          message: 'Sent feedback failed, please try again!',
        });
      }
    }
    if (userInfo.userKey) {
      if (existDraft) {
        sendWithDraft();
      } else {
        sendWithoutDraft();
      }
    }
  };

  const handleClear = () => {
    setSubject('');
    setMessageContent('');
    setAlert({
      show: true,
      variant: 'success',
      message: 'Feedback cleared successfully!',
    });
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Feedback to StartNation:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Label>
              <span className="text-danger">*</span> Subject Re:
            </Form.Label>
            <Form.Control
              type="textarea"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationCustom02">
            <Form.Label>
              <span className="text-danger">*</span> Message
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={messageContent}
              onChange={(event) => setMessageContent(event.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <ButtonGroup
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <button
            type="button"
            className="btn btn-md btn-outline-success rounded-pill"
            disabled={isClearDisabled}
            onClick={handleClear}
            style={{marginLeft: '8px'}}
          >
            Clear
          </button>
          <button
            type="button"
            className="btn btn-md btn-outline-success rounded-pill"
            disabled={isSaveDisabled}
            onClick={handleSave}
            style={{marginLeft: '8px'}}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-md btn-outline-success rounded-pill"
            disabled={isSendDisabled}
            onClick={handleSend}
            style={{marginLeft: '8px'}}
          >
            Send
          </button>
        </ButtonGroup>
        {alert?.show && alert?.variant && alert?.message && (
          <Alert
            variant={alert?.variant}
            onClose={() => setAlert({show: false})}
            dismissible
            style={{width: 'auto', marginTop: '10px'}}
            className="ml-3"
          >
            {alert?.message}
          </Alert>
        )}
      </Modal.Footer>
    </Modal>
  );
}

FeedbackModal.propTypes = {
  showModal: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default FeedbackModal;
