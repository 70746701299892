/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import Select from 'react-select';
import {userSettings} from '../store/atoms';
import CompanyPresentationLinksInstaIntro from './CompanyPresentationLinksInstaIntro';
import CompanyPresentationUpdateSavePublishedModal from './CompanyPresentationUpdateSavePublishedModal';
import CompanyPresentationUpdateSaveUnpublishedModal from './CompanyPresentationUpdateSaveUnpublishedModal';
import CompanyPresentationUpdateCancelModal from './CompanyPresentationUpdateCancelModal';

function CompanyPresentationUpdateInstaIntro(setModified) {
  const userInfo = useRecoilValue(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [presentationExist, setPresentationExist] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [isCompanyInfoExist, setIsCompanyInfoExist] = useState(false);
  const [picture, setPicture] = useState(undefined);
  const [clientIntroLink, setClientIntroLink] = useState(undefined);
  const [clientVideoIntro, setClientVideoIntro] = useState('');
  const [showSavePublishedModal, setShowSavePublishedModal] = useState(false);
  const [showSaveUnpublishedModal, setShowSaveUnpublishedModal] =
    useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const {
    formState: {isSubmitting},
    handleSubmit,
    reset,
  } = useForm();

  const [isChanged, setIsChanged] = useState(false);

  const OptionsPictures = [
    {value: '/images/space.png', label: 'General 1'},
    {value: '/images/general 2.png', label: 'General 2'},
    {value: '/images/general 3.png', label: 'General 3'},
    {value: '/images/general 4.png', label: 'General 4'},

    {
      value: '/images/AI and machine learning.png',
      label: 'AI & Machine Learning',
    },
    {value: '/images/b2b software.png', label: 'B2B software'},
    {value: '/images/big data.png', label: 'Big Data'},
    {value: '/images/biotech.png', label: 'Biotech'},
    {
      value: '/images/blockchain-crypto.png',
      label: 'Blockchain, Crypto, Defi and Web3',
    },
    {value: '/images/cybersecurity.png', label: 'Cybersecurity'},
    {value: '/images/delivery services.png', label: 'Delivery Services'},
    {value: '/images/drone technology.png', label: 'Drone Technology'},
    {value: '/images/ecommerce.png', label: 'Ecommerce'},
    {value: '/images/EdTech.png', label: 'Edtech'},
    {value: '/images/fintech.png', label: 'Fintech'},
    {value: '/images/foodtech.png', label: 'FoodTech'},
    {value: '/images/healthtech.png', label: 'HealthTech'},
    {value: '/images/IoT.png', label: 'Internet of Things'},
    {value: '/images/medtech.png', label: 'Medtech'},
    {value: '/images/real estate.png', label: 'Real Estate'},
    {value: '/images/shared mobility.png', label: 'Shared Mobility'},
    {
      value: '/images/supply chain management software.png',
      label: 'Supply Chain Management & Logistics',
    },
    {value: '/images/technology.png', label: 'Technology'}, 
    {value: '/images/VrAr.png', label: 'VR / AR'},
  ];
  const [message, setMessage] = useState('');
  const [color, setColor] = useState(undefined);
  const [location, setLocation] = useState(undefined);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [top, setTop] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [center, setCenter] = useState(false);
  const OptionsColors = [
    {value: 'white', label: 'White'},
    {value: 'black', label: 'Black'},
    {value: 'blue', label: 'Blue'},
    {value: 'grey', label: 'Grey'},
    {value: 'red', label: 'Red'},
    {value: 'yellow', label: 'Yellow'},
    {value: 'green', label: 'Green'},
  ];
  const OptionsLocations = [
    {value: '0', label: 'Center'},
    {value: '100px', label: 'Left'},
    {value: '100px', label: 'Right'},
    {value: '50%', label: 'Top'},
    {value: '1%', label: 'Bottom'},
  ];
  const [companyKey, setCompanyKey] = useState(undefined);
  const [companyMoreInfo, setCompanyMoreInfo] = useState(undefined);

  const [characterCount, setCharacterCount] = useState(
    companyMoreInfo ? companyMoreInfo.length : 0
  );

  useEffect(() => {
    async function fetchCompanyPresentation() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/presentation/findByCompanyKey/${userInfo.companyKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setPresentationExist(true);
          if (response.data[0].presentationAttributes.isPublic === true) {
            setIsPublic(true);
          } else {
            setIsPublic(false);
          }
          localStorage.setItem('presentationID', response.data[0].entityKey);
          setCompanyKey(response.data[0].companyKey);
          setClientIntroLink(
            response.data[0].presentationAttributes
              .clientIntro_videoPresentationLink
          );
          setClientVideoIntro(
            response.data[0].presentationAttributes
              .clientIntro_videoIntro
          );
          const receivedPicture = OptionsPictures.filter(
            (el) =>
              response.data[0].presentationAttributes
                .startIntro_backgroundImage === el.value
          )[0];
          setPicture(
            response.data[0].presentationAttributes.startIntro_backgroundImage
              ? receivedPicture
              : {value: '/images/space.png', label: 'General 1'}
          );

          setMessage(
            response.data[0].presentationAttributes.startIntro_overlayText
              ? response.data[0].presentationAttributes.startIntro_overlayText
              : 'Currently in stealth mode. Contact us for more info.'
          );

          setCompanyMoreInfo(
            response.data[0].presentationAttributes.startIntro_companyMoreInfo
              ? response.data[0].presentationAttributes
                  .startIntro_companyMoreInfo
              : ''
          );

          const receivedColor = response.data[0].presentationAttributes
            .startIntro_textColor
            ? OptionsColors.filter(
                (el) =>
                  response.data[0].presentationAttributes
                    .startIntro_textColor === el.value
              )[0]
            : undefined;
          setColor(receivedColor || {value: 'white', label: 'White'});

          const receivedLocation = response.data[0].presentationAttributes
            .startIntro_textLocation
            ? OptionsLocations.filter(
                (el) =>
                  response.data[0].presentationAttributes
                    .startIntro_textLocation === el.label
              )[0]
            : undefined;
          setLocation(receivedLocation || {value: '0px', label: 'Center'});

          if (receivedLocation.label === 'Right') {
            setRight(true);
          } else if (receivedLocation.label === 'Left') {
            setLeft(true);
          } else if (receivedLocation.label === 'Top') {
            setTop(true);
          } else if (receivedLocation.label === 'Bottom') {
            setBottom(true);
          } else {
            setCenter(true);
          }
        }
      } catch (error) {
        console.error(
          'CompanyPresentationUpdate found Presentation error - ',
          error
        );
      }
    }

    if (userInfo.companyKey) {
      fetchCompanyPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/company/findByEntityKey/${userInfo.companyKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          setIsCompanyInfoExist(true);
          setCompanyInfo(response.data);
        }
      } catch (error) {
        setIsCompanyInfoExist(false);
        history.push(`/company/create/info`);
      }
    }

    if (userInfo.companyKey) {
      fetchCompanyInfo();
    } else {
      setIsCompanyInfoExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCharacterCount(companyMoreInfo ? companyMoreInfo.length : 0);
  }, [companyMoreInfo]);

  async function updatePresentation() {
    const newPresentation = {
      entityKey: localStorage.getItem('presentationID'),
      companyKey,
      presentationAttributes: {
        lastSaved: 'startIntro',
        companyName: companyInfo.companyName,
        clientIntro_videoPresentationLink: clientIntroLink,
        clientIntro_videoIntro: clientVideoIntro,
        startIntro_backgroundImage: picture.value,
        startIntro_overlayText: message,
        startIntro_textColor: color.value,
        startIntro_textLocation: location.label,
        startIntro_companyMoreInfo: companyMoreInfo,
        isPublic,
      },
    };

    try {
      const presentationID = localStorage.getItem('presentationID');
      const response = await axios.put(
        `${API_SERVER_URL}/api/company/presentation/updateByEntityKey/${presentationID}`,
        newPresentation,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
        const {setModified: updateModified} = setModified;
        updateModified('startIntro');
      } else {
        setIsError(true);
        console.log('CompanyInfoUpdate update company presentation error');
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  async function addPresentation() {
    const newPresentation = {
      entityKey: userInfo.companyKey,
      companyKey: userInfo.companyKey,
      presentationAttributes: {
        lastSaved: 'startIntro',
        companyName: companyInfo.companyName,
        clientIntro_videoPresentationLink: clientIntroLink,
        clientIntro_videoIntro: clientVideoIntro,
        startIntro_backgroundImage: picture.value,
        startIntro_overlayText: message,
        startIntro_textColor: color.value,
        startIntro_textLocation: location.label,
        startIntro_companyMoreInfo: companyMoreInfo,
      },
    };

    try {
      const response = await axios.post(
        `${API_SERVER_URL}/api/company/presentation/create`,
        newPresentation,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        localStorage.setItem('presentationID', response.data.entityKey);
        setIsSuccess(true);
        const {setModified: updateModified} = setModified;
        updateModified('startIntro');
      } else {
        setIsError(true);
        console.log('CompanyInfoUpdate created new presentation failed.');
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      console.log('CompanyInfoUpdate created new presentation error - ', error);
    }
  }

  const onSubmit = async (data) => {
    setIsError(false);
    setIsSuccess(false);
    if (isPublic) {
      setShowSavePublishedModal(false);
    } else {
      setShowSaveUnpublishedModal(false);
    }
    reset({});
    return presentationExist ? updatePresentation(data) : addPresentation(data);
  };

  const handleOnClickSaveOrAdd = (e) => {
    e.preventDefault();
    if (isPublic) {
      setShowSavePublishedModal(true);
    } else {
      setShowSaveUnpublishedModal(true);
    }
  };

  const handleOnClickCancel = (e) => {
    e.preventDefault();
    setShowCancelModal(true);
  };

  const handleCancel = () => {
    history.push(`/company/presentation/preview`);
  };

  if (!isCompanyInfoExist) {
    return (
      <Container className="shadow rounded">
        <Row>
          <Col>
            <CompanyPresentationLinksInstaIntro
              linkKey="linkUpdate"
              publishEnable={false}
            />
          </Col>
        </Row>
        <Row className="py-5 m-5">
          <Col>
            <h2>Please input company info first.</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  const handleTextareaChange = (event) => {
    const inputValue = event.target.value;
    setCompanyMoreInfo(inputValue);
    setCharacterCount(inputValue.length);
    setIsChanged(true);
  };

  return (


    <Container className="shadow rounded">

      <Row className="mb-3">
        <Col>
          <CompanyPresentationLinksInstaIntro
            linkKey="linkUpdate"
            publishEnable={presentationExist}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            {isError && !isSuccess && (
              <small className="mt-3 d-inline-block text-danger">
                Something went wrong. Please try again later.
              </small>
            )}
            {isSuccess && (
              <small className="mt-3 d-inline-block text-success">
                Submit success!
              </small>
            )}
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="col-sm-4 col-xl-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col>
              <label>Select Background</label>
              <Select
                closeMenuOnSelect
                value={{
                  label: picture && picture.label,
                  value: picture && picture.value,
                }}
                options={OptionsPictures}
                onChange={(e) => {
                  setIsChanged(true);
                  setPicture(e);
                }}
              />
            </Col>
            <br />
            <Col>
              <label>Background Text</label>
              <input
                type="text"
                id="message"
                name="message"
                style={{width: '310px'}}
                onChange={(event) => {
                  setIsChanged(true);
                  setMessage(event.target.value);
                }}
                value={message}
              />
            </Col>
            <br />
            <Col>
              <label>Text Color</label>
              <Select
                closeMenuOnSelect
                value={{
                  label: color && color.label,
                  value: color && color.value,
                }}
                options={OptionsColors}
                onChange={(e) => {
                  setIsChanged(true);
                  setColor(e);
                }}
              />
            </Col>
            <br />
            <Col>
              <label>Text Location</label>
              <Select
                closeMenuOnSelect
                value={{
                  label: location && location.label,
                  value: location && location.value,
                }}
                options={OptionsLocations}
                onChange={(e) => {
                  setIsChanged(true);
                  setLocation(e);
                  if (e.label === 'Top') {
                    setTop(true);
                    setLeft(false);
                    setRight(false);
                    setBottom(false);
                    setCenter(false);
                  } else if (e.label === 'Bottom') {
                    setTop(false);
                    setLeft(false);
                    setRight(false);
                    setBottom(true);
                    setCenter(false);
                  } else if (e.label === 'Left') {
                    setTop(false);
                    setLeft(true);
                    setRight(false);
                    setBottom(false);
                    setCenter(false);
                  } else if (e.label === 'Right') {
                    setTop(false);
                    setLeft(false);
                    setRight(true);
                    setBottom(false);
                    setCenter(false);
                  } else {
                    setTop(false);
                    setLeft(false);
                    setRight(false);
                    setBottom(false);
                    setCenter(true);
                  }
                }}
              />
            </Col>
            <br />
            <Col>
              <label>What&apos;s exciting about your company?</label>
              <textarea
                rows="4"
                cols="36"
                type="text"
                maxLength="250"
                id="moreAboutCompany"
                name="moreAboutCompany"
                value={companyMoreInfo}
                onChange={handleTextareaChange}
              />
              <div className="d-flex justify-content-end">
                {characterCount}/250
              </div>
            </Col>
            <Col>
              <button
                type="button"
                className="mx-2 px-5 my-3 btn btn-outline-success rounded-pill"
                onClick={handleOnClickCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleOnClickSaveOrAdd}
                disabled={isSubmitting || !isChanged}
                className="btn btn-success px-5 my-3 rounded-pill"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1" />
                )}
                {presentationExist ? 'Save' : 'Add'}
              </button>
            </Col>
          </form>
        </Col>

        <Col
          style={{
            width: 'max-content',
            height: 'max-content',
            textAlign: `${center ? location.value : 'center'}`,
            margin: 'auto',
            position: 'relative',
          }}
        >
          {picture !== undefined && (
            <img src={picture.value} alt={picture.label} />
          )}
          {message !== undefined && (
            <p
              style={{
                position: 'absolute',
                margin: 'auto',
                height: '100%',
                zIndex: '2',
                fontSize: '-webkit-xxx-large',
                color: `${color === undefined ? 'white' : color.value}`,
                textAlign: `${
                  left ? 'left' : right ? 'right' : center ? 'center' : 'center'
                }`,
                top: `${top ? location.value : '80%'}`,
                bottom:`${bottom ? location.value : '50%'}`,
                left: `${left ? location.value : '18%'}`,
                right: `${right ? location.value : '18%'}`,
              }}
            >
              {message}
            </p>
          )}
        </Col>
      </Row>

      <CompanyPresentationUpdateSavePublishedModal
        showModal={showSavePublishedModal}
        handleSubmit={handleSubmit(onSubmit)}
        setShowModal={setShowSavePublishedModal}
        setIsPublic={setIsPublic}
        isPublic={isPublic}
      />
      <CompanyPresentationUpdateSaveUnpublishedModal
        showModal={showSaveUnpublishedModal}
        handleSubmit={handleSubmit(onSubmit)}
        setShowModal={setShowSaveUnpublishedModal}
      />
      <CompanyPresentationUpdateCancelModal
        showModal={showCancelModal}
        handleCancel={handleCancel}
        setShowModal={setShowCancelModal}
      />
    </Container>
  );
}

export default CompanyPresentationUpdateInstaIntro;
