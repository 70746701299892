import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import fp from 'lodash/fp';
import axios from 'axios';
import {useRecoilState} from 'recoil';
import {useForm} from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import usStatesList from '../store/usstates';
import countryList from '../store/countryList';
import CompanyInfoLinks from './CompanyInfoLinks';
import 'react-phone-input-2/lib/style.css';
import industriesList from '../store/industriesListForClient';

import {userSettings} from '../store/atoms';

function CompanyInfoUpdate() {
  const [userInfo, setUserInfo] = useRecoilState(userSettings);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCompanyInfoExist, setIsCompanyInfoExist] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const companyAttributesList = [
    'country',
    'stateOfRegistration',
    'name',
    'email',
    'EIN',
    'EINType',
    'number',
    'address1',
    'address2',
    'city',
    'state',
    'websiteLink',
  ];
  const EINTypes = {
    'C-Corp': 'C-Corp',
  };
  const OptionsEmployeeNumber = {
    '<10': '0 - 10',
    '10-50': '10 - 50',
    '>50': '50 +',
  };
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherIndustries, setOtherIndustries] = useState('');
  const [industries, setIndustries] = useState([]);
  const animatedComponents = makeAnimated();

  const validationSchema = yup.object().shape({
    country: yup.string().required('Country is required'),
    name: yup
      .string()
      .required('Company Name is required')
      .max(512, 'Company name has a maximum limit of 512 characters.'),
    stateOfRegistration: yup
      .string()
      .required('State of Registration is required'),
    email: yup.string().required('Business Contact Email is required').email(),
    EIN: yup
      .string()
      .required('EIN Number is required')
      .max(10, 'EIN number has a maximum limit of 9 digits.')
      .matches(/^\d{2}-?\d{7}$/, 'EIN format 12-3456789'),
    EINType: yup.string().required('EIN Type is required'),
    websiteLink: yup
      .string()
      .matches(
        /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        {message: 'Please enter a valid Website URL', excludeEmptyString: true}
      ),
    address1: yup
      .string()
      .max(512, 'Address has a maximum limit of 512 characters.'),
    city: yup
      .string()
      .required('City is required')
      .max(512, 'City has a maximum limit of 512 characters.'),
    state: yup.string().required('State is required'),
    zip: yup
      .string()
      .nullable()
      .test(
        'zip-check',
        'Zip must have 5 digits',
        (value) => !value || /^\d{5}$/.test(value)
      ),
  });

  const companyInfoAttributesDefaultValues = {
    country: 'US',
  };

  const {
    formState: {errors, isSubmitting},
    handleSubmit,
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: companyInfoAttributesDefaultValues,
    mode: 'onBlur',
  });

  useEffect(() => {
    async function fetchCompanyUser() {
      try {
        let response = await axios.get(
          `${API_SERVER_URL}/api/company/user/findByUserKey/${userInfo.userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          const {companyKey} = response.data;
          try {
            response = await axios.get(
              `${API_SERVER_URL}/api/company/findByEntityKey/${companyKey}`,
              {
                headers: {Authorization: `Bearer ${userInfo.token}`},
              }
            );
            if (response.status === 200) {
              localStorage.setItem('companyKey', companyKey);
              const newUserInfo = {...userInfo};
              newUserInfo.companyKey = companyKey;
              setUserInfo(newUserInfo);
              setIsCompanyInfoExist(true);
              setCompanyInfo(response.data);
              let tempOptions = [];
              if (response.data.companyAttributes.industries !== undefined) {
                if (response.data.companyAttributes.industries !== '') {
                  const arr =
                    response.data.companyAttributes.industries.split('^');
                  if (
                    (arr.length === 2 && arr[0] !== 'Other') ||
                    (arr.length > 0 && arr[arr.length - 2] !== 'Other')
                  ) {
                    tempOptions = fp.map((el) => ({
                      value: el,
                      label: el,
                    }))(arr);
                  } else {
                    const otherContent = arr.pop();
                    setOtherIndustries(otherContent);
                    tempOptions = fp.map((el) => ({
                      value: el,
                      label: el,
                    }))(arr);
                  }
                }
              }
              setIndustries(tempOptions);
              companyAttributesList.forEach((element) => {
                setValue(element, response.data.companyAttributes[element]);
              });
              setPhoneNumber(response.data.companyAttributes.phoneNumber);
            }
          } catch (error) {
            setIsCompanyInfoExist(false);
          }
        }
      } catch (error) {
        setIsCompanyInfoExist(false);
      }
    }

    if (userInfo.userKey) {
      fetchCompanyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const list = fp.filter((el) => el.value === 'Other')(industries);
    if (list.length !== 0) {
      setShowOtherInput(true);
    } else {
      setOtherIndustries('');
      setShowOtherInput(false);
    }
  }, [industries, setShowOtherInput]);

  async function updateCompanyInfo(data) {
    let tempArr = fp.map((el) => el.value)(industries);
    if (otherIndustries !== '') {
      tempArr = tempArr.concat(otherIndustries);
    }
    const newCompanyInfo = JSON.parse(JSON.stringify(companyInfo));
    newCompanyInfo.companyName = data.name;
    newCompanyInfo.companyAttributes.phoneNumber = phoneNumber;
    newCompanyInfo.companyAttributes.industries = tempArr.join('^');
    companyAttributesList.forEach((element) => {
      newCompanyInfo.companyAttributes[element] = data[element];
    });
    try {
      const response = await axios.put(
        `${API_SERVER_URL}/api/company/updateByEntityKey/${userInfo.companyKey}`,
        newCompanyInfo,
        {headers: {Authorization: `Bearer ${userInfo.token}`}}
      );
      if (response.status === 200) {
        setIsSuccess(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  const createCompanyUser = async () => {
    const companyUser = {
      userKey: userInfo.userKey,
      companyKey: localStorage.getItem('companyKey'),
      companyUserAttributes: {},
    };
    try {
      const createCompanyUserResponse = await axios.post(
        `${API_SERVER_URL}/api/company/user/create`,
        companyUser,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (createCompanyUserResponse.status === 200) {
        setIsSuccess(true);
      }
      history.push(`/company/user`);
    } catch (error) {
      history.push(`/company/user`);
    }
  };

  async function createCompanyInfo(data) {
    const newProfile = {
      companyName: data.name,
      companyAttributes: {
        phoneNumber,
        industries: '',
      },
    };
    companyAttributesList.forEach((element) => {
      newProfile.companyAttributes[element] = data[element];
    });

    try {
      const createCompanyResponse = await axios.post(
        `${API_SERVER_URL}/api/company/create`,
        newProfile,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (createCompanyResponse.status === 200) {
        const companyKey = createCompanyResponse.data.entityKey;
        localStorage.setItem('companyKey', companyKey);
        const newUserInfo = {...userInfo};
        newUserInfo.companyKey = companyKey;
        setUserInfo(newUserInfo);
        setIsSuccess(true);
        try {
          const findCompanyUserResponse = await axios.get(
            `${API_SERVER_URL}/api/company/user/findByUserKey/${userInfo.userKey}`,
            {headers: {Authorization: `Bearer ${userInfo.token}`}}
          );
          if (findCompanyUserResponse.status === 200) {
            console.log(
              'CompanyInfoUpdate create new company found company user - ',
              findCompanyUserResponse.data.companyKey
            );
          }
        } catch (error) {
          console.error(
            'CompanyInfoUpdate create new company found company user error - ',
            error
          );
          await createCompanyUser();
        }
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
    }
  }

  const onSubmit = async (data) => {
    setIsError(false);
    setIsSuccess(false);

    if (isCompanyInfoExist) {
      await updateCompanyInfo(data);
    } else {
      await createCompanyInfo(data);
    }

    window.location.reload();
  };

  return (
    <Container className="mb-5">
      <Row>
        <Col>
          <CompanyInfoLinks linkKey="companyInfo" />
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="mb-3">
              {isError && !isSuccess && (
                <small className="mt-3 d-inline-block text-danger">
                  Something went wrong. Please try again later.
                </small>
              )}
              {isSuccess && (
                <small className="mt-3 d-inline-block text-success">
                  Submit success!
                </small>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} className="shadow rounded">
            <h4 className="my-4 text-center">
              Corporate Info<abbr className="text-danger">*</abbr>
            </h4>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputCountry"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                Country<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <select
                  {...register('country')}
                  className={`form-control ${
                    errors.country ? 'is-invalid' : ''
                  }`}
                  id="userInputCountry"
                  aria-describedby="userInputCountryHelp"
                >
                  <option value="">Choose...</option>
                  {countryList.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={index} value={item.code}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div id="einHelp" className="form-text">
                  <small>
                    (We are only supporting US businesses at this time.)
                  </small>
                </div>
                <div className="invalid-feedback">
                  {errors.country?.message}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <label
                htmlFor="userInputStateOfRegistration"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                State of Registration<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <select
                  {...register('stateOfRegistration')}
                  className={`form-control ${
                    errors.stateOfRegistration ? 'is-invalid' : ''
                  }`}
                  id="userInputStateOfRegistration"
                  aria-describedby="userInputStateOfRegistration"
                >
                  <option value="">Choose...</option>
                  {Object.entries(usStatesList).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {errors.stateOfRegistration?.message}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <label
                htmlFor="userInputCompanyName"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                Company Name<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('name')}
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  id="userInputCompanyName"
                  aria-describedby="userInputCompanyNameHelp"
                />
                <div className="invalid-feedback">{errors.name?.message}</div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <label
                htmlFor="otherIndustries"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                Website link
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('websiteLink')}
                  className={`form-control ${
                    errors.websiteLink ? 'is-invalid' : ''
                  }`}
                  id="userInputWebsiteLink"
                  aria-describedby="userInputWebsiteLinkHelp"
                  placeholder="www.example.com"
                />
                <div className="invalid-feedback">
                  {errors.websiteLink?.message}
                </div>
              </div>
            </div>
            <div className="form-group row mb-4">
              <label
                htmlFor="otherIndustries"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                Industry
              </label>
              <div className="col-sm-8 col-xl-9">
                <Select
                  closeMenuOnSelect
                  components={animatedComponents}
                  value={industries}
                  isMulti
                  options={industriesList}
                  onChange={(e) => setIndustries(e)}
                />
              </div>
            </div>
            {showOtherInput && (
              <div className="form-group row mb-4">
                <label className="col-sm-4  col-xl-3 col-form-label">
                  <span className="mr-3" />
                  Other
                </label>
                <div className="col-sm-8 col-xl-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please specify industries separated by ,"
                    id="otherIndustries"
                    value={otherIndustries}
                    onChange={(e) => setOtherIndustries(e.target.value)}
                    required
                  />
                </div>
              </div>
            )}
            <div className="form-group row my-4">
              <label
                htmlFor="userInputEIN"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Business Entity Type<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <select
                  {...register('EINType')}
                  className={`form-control ${
                    errors.EINType ? 'is-invalid' : ''
                  }`}
                  id="userInputEINType"
                  aria-describedby="userInputEINTypeHelp"
                >
                  <option value="">Choose...</option>
                  {Object.entries(EINTypes).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  {errors.EINType?.message}
                </div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputEIN"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                EIN <br />
                <abbr className="col-form-label"> (e.g. 12-3456789) </abbr>
                <abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('EIN')}
                  className={`form-control ${errors.EIN ? 'is-invalid' : ''}`}
                  id="userInputEIN"
                  aria-describedby="userInputEINHelp"
                />
                <div className="invalid-feedback">{errors.EIN?.message}</div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputNumberOfEmployees"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Number of Employees
              </label>
              <div className="col-sm-8 col-xl-9">
                <select
                  {...register('number')}
                  className={`form-control ${
                    errors.number ? 'is-invalid' : ''
                  }`}
                  id="userInputNumberOfEmployees"
                  aria-describedby="userInputNumberOfEmployeesHelp"
                >
                  <option value="">Choose...</option>
                  {Object.entries(OptionsEmployeeNumber).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">{errors.number?.message}</div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputCompanyPhone"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                Business Contact Phone<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <PhoneInput
                  country="us"
                  inputStyle={{minWidth: '100%'}}
                  value={phoneNumber}
                  inputProps={{
                    required: true,
                  }}
                  onChange={setPhoneNumber}
                />
                <div>
                  <div className="invalid-feedback">
                    Business Contact Phone is required.
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputEmail"
                className="col-sm-4  col-xl-3  col-form-label"
              >
                Business Contact Email<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('email')}
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  id="userInputEmail"
                  aria-describedby="userInputEmailHelp"
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputAddress1"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                Address 1
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('address1')}
                  className={`form-control ${
                    errors.address1 ? 'is-invalid' : ''
                  }`}
                  id="userInputAddress1"
                  aria-describedby="userInputAddress1Help"
                />
                <div className="invalid-feedback">
                  {errors.address1?.message}
                </div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputAddress2"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                Address 2
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('address2')}
                  className={`form-control ${
                    errors.address2 ? 'is-invalid' : ''
                  }`}
                  id="userInputAddress2"
                  aria-describedby="userInputAddress2Help"
                />
                <div className="invalid-feedback">
                  {errors.address2?.message}
                </div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputAddressCity"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                City<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('city')}
                  className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                  id="userInputAddressCity"
                  aria-describedby="userInputAddressCityHelp"
                />
                <div className="invalid-feedback">{errors.city?.message}</div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputAddressState"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                State<abbr className="text-danger">*</abbr>
              </label>
              <div className="col-sm-8 col-xl-9">
                <select
                  {...register('state')}
                  className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                  id="userInputAddressState"
                  aria-describedby="userInputAddressStateHelp"
                >
                  <option value="">Choose...</option>
                  {Object.entries(usStatesList).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">{errors.state?.message}</div>
              </div>
            </div>
            <div className="form-group row my-4">
              <label
                htmlFor="userInputAddressZip"
                className="col-sm-4  col-xl-3 col-form-label"
              >
                Zip
              </label>
              <div className="col-sm-8 col-xl-9">
                <input
                  type="text"
                  {...register('zip')}
                  className={`form-control ${errors.zip ? 'is-invalid' : ''}`}
                  id="userInputAddressZip"
                  aria-describedby="userInputAddressZipHelp"
                />
                <div className="invalid-feedback">{errors.zip?.message}</div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="my-2">
          <Col>
            <div className="row my-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="mx-2 btn btn-success px-5 my-3 rounded-pill"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1" />
                )}
                {isCompanyInfoExist ? 'Save' : 'Add'}
              </button>
              <Link
                to="/company/talents/find"
                className="mx-2 px-5 my-3 btn btn-outline-success rounded-pill"
              >
                Cancel
              </Link>
            </div>
          </Col>
        </Row>
      </form>
    </Container>
  );
}

export default CompanyInfoUpdate;
