import React from 'react';
import {Container, Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

function Home() {
  return (
    <Container fluid className="marketing position-relative">
      <Row className="featurette py-5">
        <div className="col-md-5 offset-md-1 order-md-1">
          <h3 className="mt-5 w-100">
            <span>
              The place where remote talents and American startup companies join
              forces to build the future.
            </span>
          </h3>
        </div>
        <div className="col-md-5 order-md-2">
          <img
            src="images/homepage-main-theme-s.png"
            className="img-fluid"
            alt="COMPANY"
          />
        </div>
      </Row>
      <Row className="featurette py-5">
        <div className="col-md-5 order-md-2">
          <h1 className="featurette-heading text-warning">COMPANIES</h1>
          <h3 className="mt-5 w-100">
            <span>
              Build a passionate team of talents with equity to start up your
              business.
            </span>
          </h3>
          <NavLink
            to="/signup?type=COMPANY"
            className="my-4 px-5 py-3 btn btn-outline-success btn-lg rounded-pill"
          >
            Sign Up
          </NavLink>
        </div>
        <div className="col-md-5 offset-md-1 order-md-1">
          <img
            src="images/homepage-company-s.png"
            className="img-fluid"
            alt="COMPANY"
          />
        </div>
      </Row>
      <Row className="featurette bg-light py-5">
        <div className="col-md-5 offset-md-1  order-md-1">
          <h1 className="featurette-heading text-warning">TALENTS</h1>
          <h3 className="mt-5 w-100">
            <span>
              Earn valuable career experience and equity by investing spare
              hours to help build startups aligned with your passions.
            </span>
          </h3>
          <NavLink
            to="/signup?type=TALENT"
            className="my-4 px-5 py-3 btn btn-outline-success btn-lg rounded-pill"
          >
            Sign Up
          </NavLink>
        </div>
        <div className="col-md-5 order-md-2">
          <img
            src="images/homepage-talent-s.png"
            className="img-fluid"
            alt="TALENT"
          />
        </div>
      </Row>
      <Row className="featurette bg-light py-5" />
    </Container>
  );
}

export default Home;
