import React, {useEffect, useState, useMemo} from 'react';
import {Redirect} from 'react-router-dom';
import {Container, Row, Col, Navbar, Nav, NavDropdown} from 'react-bootstrap';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import userTypes from '../store/constants';
import LogoImage from './LogoImage';
import Share from '../common/Share';
import {userSettings, newFeedbackInfoState} from '../store/atoms';
import {FeedbackModal} from '../common/feedback/FeedbackModal';
import SystemAlert from '../common/SystemAlert';

const platformShareButtonStyle = {
  width: '141px',
  height: '40px',
  padding: '8px 45px',
  borderRadius: '32px',
  border: 'none',
  background: '#f1881a',
  color: '#fff',
  cursor: 'pointer',
  fontWeight: 'bold',
};

function TalentLayoutHeader() {
  const authenticated = localStorage.getItem('authenticated');
  const loggedInAsUserType = localStorage.getItem('loggedInAsUserType');
  const [messageModal, setMessageModel] = useState(false);
  const [isFeedbackReceiver, setIsFeedbackReceiver] = useState(false);
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  let talentHasThePermission = false;
  const systemAlertInfo = useRecoilValue(newFeedbackInfoState);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  useEffect(() => {
    async function getUnreadMessageCount() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/talent/message/receive/unreadMessageCount/${userInfo.userKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          setUnreadMessageCount(response?.data || 0);
        }
        console.log('Talent getUnreadMessageCount response?.data', response?.data);
      } catch (error) {
        console.error('Talent get unreadMessageCount error', error);
      }
    }

    if (userInfo.userTypes === 'TALENT') {
      getUnreadMessageCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchFeedbackUserKey() {
    const FEEDBACK_USER_EMAIL = 'feedback@startnation.live';

    try {
      const response = await axios.get(
        `${API_SERVER_URL}/api/user/findByEmail/${FEEDBACK_USER_EMAIL}`,
        {
          headers: {Authorization: `Bearer ${userInfo.token}`},
        }
      );
      if (response.status === 200) {
        if (response.data.userKey === userInfo.userKey) {
          setIsFeedbackReceiver(true);
        }
      }
    } catch (error) {
      console.error('feedback user not found - ', error);
    }
  }

  const feedbackReceiverChecker = useMemo(() => {
    if (authenticated && loggedInAsUserType === userTypes.TALENT.value) {
      fetchFeedbackUserKey();
    }
    // eslint-disable-next-line
  }, [authenticated, loggedInAsUserType, userTypes, fetchFeedbackUserKey]);

  if (authenticated && loggedInAsUserType === userTypes.TALENT.value) {
    talentHasThePermission = true;
  }

  if (!talentHasThePermission) {
    return <Redirect to="/" />;
  }

  const handleLogout = () => {
    localStorage.removeItem('authenticated');
    localStorage.removeItem('entityKey');
    localStorage.removeItem('userKey');
    localStorage.removeItem('companyKey');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('userTypes');
    localStorage.removeItem('loggedInAsUserType');
    localStorage.removeItem('emailAddress');
    localStorage.removeItem('token');
    window.location = '/';
  };

  return (
    <Container fluid>
      <Row>
        <Col xl={{span: 10, offset: 1}}>
          <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand href="/">
              <LogoImage />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="/talent/startmedia" style={{color: '#5CA53D'}}>
                  Home
                </Nav.Link>
                <Nav.Link href="/talent/search" style={{color: '#5CA53D'}}>
                  Search
                </Nav.Link>
                {/* <Nav.Link href="/talent/jobs" style={{color: '#5CA53D'}}>
                  Search
                </Nav.Link>
                <Nav.Link href="/talent/companies" style={{color: '#5CA53D'}}>
                  Companies
                </Nav.Link> */}
                {/* <Nav.Link
                  eventKey="companyEquityHeld"
                  href="/talent/equity/held"
                  style={{color: '#5CA53D'}}
                >
                  StartCoin
                </Nav.Link> */}
                <Share
                  buttonStyle={platformShareButtonStyle}
                  buttonText="Share"
                  modalText="Share StartNation with your friends"
                />
              </Nav>
              <Nav>
                <NavDropdown
                  title={
                    <i className="bi bi-person" style={{color: '#5CA53D'}} />
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="/talent/account">
                    <i className="bi bi-person-fill mr-1" />
                    Account Info
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/talent/info">
                    <i className="bi bi-info-circle-fill mr-1" />
                    Talent Info
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/talent/profiles">
                    <i className="bi bi-file-earmark-person-fill mr-1" />
                    Profiles
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>
                    <i className="bi bi-box-arrow-right mr-1" />
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title={
                    <span
                      style={{position: 'relative', display: 'inline-block'}}
                    >
                      <i className="bi bi-envelope-fill" />
                      {unreadMessageCount > 0 && (
                        <span
                          style={{
                            position: 'absolute',
                            top: '-12px',
                            left: '11px',
                            textAlign: 'center',
                          }}
                          className="badge text-success"
                        >
                          ({unreadMessageCount})
                        </span>
                      )}
                    </span>
                  }
                  id="nav-dropdown"
                >
                  <NavDropdown.Item href="/talent/message/inbox">
                    Inbox
                    {unreadMessageCount > 0 && (
                      <span className="badge text-success">
                        ({unreadMessageCount})
                      </span>
                    )}
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/talent/message/sent">
                    Sent
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/talent/message/draft">
                    Draft
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  href="#"
                  onClick={() => setMessageModel(true)}
                  style={{color: 'grey'}}
                >
                  <i className="bi bi-pencil-square" />
                  User Feedback
                </Nav.Link>
                <NavDropdown
                  id="nav-dropdown"
                  style={{margin: '0px 0px 0px -12px'}}
                >
                  <NavDropdown.Item href="/talent/user-feedback/inbox">
                    Inbox
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/talent/user-feedback/sent">
                    Sent
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/talent/user-feedback/draft">
                    Draft
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {messageModal && !isFeedbackReceiver && (
            <FeedbackModal
              showModal={messageModal}
              setShowModal={setMessageModel}
              feedbackReceiverChecker={feedbackReceiverChecker}
            />
          )}
          <hr />
        </Col>
        {systemAlertInfo.show && (
          <Col>
            <SystemAlert info={systemAlertInfo} />
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default TalentLayoutHeader;
