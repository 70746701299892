import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {userVideoWatchingState} from '../store/atoms';
import {PresentationTypes} from './Utils/PresentationTypes';
import VideoPresentationBackground from './VideoPresentationImage';
import './videos.css';

function Post({video}) {
  const history = useHistory();
  const [userVideoWatching, setUserVideoWatching] = useRecoilState(
    userVideoWatchingState
  );
  const handleVideoClick = () => {
    const newUserVideos = {...userVideoWatching, watchingId: video.entityKey};
    setUserVideoWatching(newUserVideos);
    history.push(`/talent/startmedia/video/${video.entityKey}`);
  };
  const aboutUs =
    (video.presentationAttributes?.lastSaved === PresentationTypes.ClientIntro
      ? video.presentationAttributes?.clientIntro_videoIntro
      : video.presentationAttributes?.startIntro_companyMoreInfo) ||
    'We are building content. Welcome back later.';

  return (
    <div className="video">
      <div className="video_title">
        {video?.companyName ||
          video?.presentationAttributes?.companyName ||
          'Company'}
      </div>
      <div
        className="video_top"
        onClick={handleVideoClick}
        onKeyDown={handleVideoClick}
        role="button"
        tabIndex={0}
      >
        <VideoPresentationBackground
          video={video.presentationAttributes}
          showOverlayText
        />
      </div>

      <div className="video_details">
        <span>{aboutUs}</span>
      </div>
    </div>
  );
}

Post.propTypes = {
  video: PropTypes.object.isRequired,
};

export default Post;
