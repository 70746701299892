import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useRecoilValue} from 'recoil';
import {Row, Col, Card, ListGroup} from 'react-bootstrap';
import fp from 'lodash/fp';
import moment from 'moment';
import MessageReplyModal from '../MessageReplyModal';
import {userSettings} from '../../store/atoms';

function MessageInbox() {
  const API_SERVER_URL = process.env.REACT_APP_API_URL;
  const userInfo = useRecoilValue(userSettings);
  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    async function setMessageReadStatus(entityKey) {
      try {
        const response = await axios.put(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/receive/setMessageReadStatus/${entityKey}?readStatus=true`,
          {},
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          console.log('setMessageReadStatus success');
        }
      } catch (error) {
        console.error('setMessageReadStatus failed - ', error);
      }
    }

    async function fetchInboxMessage() {
      try {
        const response = await axios.get(
          `${API_SERVER_URL}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/receive/findByRecipientUserKey/${
            userInfo.userKey
          }`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          const sortedMessages = response.data.sort(
            (a, b) =>
              new Date(b.receivedMessageAttributes.modifyTime) - new Date(a.receivedMessageAttributes.modifyTime)
          );
          setMessageList(
            Object.entries(
              fp.groupBy('receivedMessageAttributes.id')(sortedMessages)
            )
          );
          if (response?.data && response.data.length > 0) {
            response.data.forEach((message) => {
              setMessageReadStatus(message?.entityKey);
            });
          }
        }
      } catch (error) {
        console.error('Failed to get inbox messages - ', error);
      }
    }

    if (userInfo.userKey) {
      fetchInboxMessage();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <p>Message Inbox</p>
      <Row className="mt-1">
        {messageList.length > 0 &&
          messageList.map((item) => (
            <MessageCard
              key={item.entityKey}
              message={item}
              userInfo={userInfo}
              apiServer={API_SERVER_URL}
              messageList={messageList}
              setMessageList={setMessageList}
            />
          ))}
      </Row>
    </>
  );
}

function MessageCard({
  apiServer,
  message,
  messageList,
  setMessageList,
  userInfo,
}) {
  const [companyInfo, setCompanyInfo] = useState({});
  const [replyModal, setReplyModal] = useState(false);
  const [name, setName] = useState('');
  useEffect(() => {
    async function fetchCompanyInfo() {
      try {
        const response = await axios.get(
          `${apiServer}/api/company/user/findByUserKey/${message[1][0].fromUserKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          try {
            const responseCompany = await axios.get(
              `${apiServer}/api/company/findByEntityKey/${response.data.companyKey}`,
              {headers: {Authorization: `Bearer ${userInfo.token}`}}
            );
            if (responseCompany.status === 200) {
              setCompanyInfo(responseCompany.data);
            }
          } catch (error) {
            console.error('Failed to get inbox messages - ', error);
          }
        }
      } catch (error) {
        console.error('Failed to get company info - ', error);
      }
    }

    async function fetchTalentInfo(userKey) {
      try {
        const response = await axios.get(
          `${apiServer}/api/user/findByUserKey/${userKey}`,
          {
            headers: {Authorization: `Bearer ${userInfo.token}`},
          }
        );
        if (response.status === 200) {
          let fName = '';
          let lName = '';
          if (response.data.firstName) {
            fName =
              response.data.firstName.charAt(0).toUpperCase() +
              response.data.firstName.substring(1);
          }
          if (response.data.lastName) {
            lName = response.data.lastName.charAt(0).toUpperCase();
          }
          setName(`${fName} ${lName} `);
        }
      } catch (error) {
        console.log('Find talent info error - ', error);
      }
    }

    if (userInfo.userKey && message[1][0].fromUserType === 'COMPANY') {
      fetchCompanyInfo();
    }
    if (userInfo.userKey && message[1][0].fromUserType === 'TALENT') {
      fetchTalentInfo(message[1][0].fromUserKey);
    }
    // eslint-disable-next-line
  }, []);

  const handleDelete = () => {
    async function deleteMessage(entityKey) {
      try {
        const response = await axios.delete(
          `${apiServer}/api/${userInfo.loggedInAsUserType.toLowerCase()}/message/receive/deleteByEntityKey/${entityKey}`,
          {headers: {Authorization: `Bearer ${userInfo.token}`}}
        );
        if (response.status === 200) {
          if (message[1].length === 1) {
            setMessageList(
              messageList.filter((msg) => msg[1][0].entityKey !== entityKey)
            );
          } else {
            setMessageList(messageList.filter((msg) => msg[0] !== message[0]));
          }
        }
      } catch (error) {
        console.error('Failed to delete message - ', error);
      }
    }
    if (userInfo.userKey) {
      if (message[1].length === 1) {
        deleteMessage(message[1][0].entityKey);
      } else {
        message[1].forEach((element) => {
          deleteMessage(element.entityKey);
        });
      }
    }
  };

  return (
    <>
      {message && message[1].length === 1 && (
        <Col md={12} className="my-3">
          <Card className="bg-light">
            {message[1][0].fromUserType === 'COMPANY' &&
              !message[1][0].receivedMessageAttributes.messageType && (
                <Card.Header>Message From Company</Card.Header>
              )}
            {message[1][0].fromUserType === 'COMPANY' &&
              message[1][0].receivedMessageAttributes.messageType ===
                'Application' && (
                <Card.Header>Message From Company - Applied a job</Card.Header>
              )}
            {message[1][0].fromUserType === 'TALENT' &&
              !message[1][0].receivedMessageAttributes?.jobTitle && (
                <Card.Header>Message From {name}(Talent):</Card.Header>
              )}
            {message[1][0].fromUserType === 'TALENT' &&
              message[1][0].receivedMessageAttributes?.jobTitle && (
                <Card.Header>Message From {name}(Talent) for Job:</Card.Header>
              )}
            {message[1][0].fromUserType === 'INVESTOR' && (
              <Card.Header>
                Message From Investor:{message[1][0].fromUserKey}
              </Card.Header>
            )}
            {message[1].map((item) => (
              // eslint-disable-next-line react/jsx-key
              <Card.Body>
                <dl className="row my-3">
                  {item.fromUserType === 'TALENT' &&
                    item.receivedMessageAttributes?.jobTitle && (
                      <>
                        <dt className="col-sm-4 text-muted">Job Title:</dt>
                        <dd className="col-sm-8">
                          {item.receivedMessageAttributes?.jobTitle}
                        </dd>
                      </>
                    )}

                  {item.fromUserType === 'COMPANY' &&
                    !item.receivedMessageAttributes.messageType && (
                      <>
                        <dt className="col-sm-4 text-muted">Company Name:</dt>
                        <dd className="col-sm-8">
                          {companyInfo && companyInfo.companyName}
                        </dd>
                      </>
                    )}

                  {item.fromUserType &&
                    item.receivedMessageAttributes.messageType ===
                      'Application' && (
                      <>
                        <dt className="col-sm-4 text-muted">Job Title:</dt>
                        <dd className="col-sm-8">
                          {item.receivedMessageAttributes.jobTitle}
                        </dd>
                      </>
                    )}

                  <dt className="col-sm-4 text-muted">Subject:</dt>
                  <dd className="col-sm-8">
                    {item.receivedMessageAttributes?.subject}
                  </dd>

                  <dt className="col-sm-4 text-muted">Message:</dt>
                  <dd className="col-sm-8">
                    {item.receivedMessageAttributes?.body}
                  </dd>

                  <dt className="col-sm-4 text-muted">Receive Time:</dt>
                  <dd className="col-sm-8">
                    {moment(item.receivedMessageAttributes?.modifyTime).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    )}
                  </dd>
                </dl>
                {replyModal && (
                  <MessageReplyModal
                    showModal={replyModal}
                    setShowModal={setReplyModal}
                    message={item}
                  />
                )}
              </Card.Body>
            ))}
            <Card.Footer>
              <button
                type="button"
                className="btn btn-md btn-outline-success rounded-pill"
                onClick={() => setReplyModal(true)}
              >
                Reply
              </button>
              <button
                type="button"
                className="btn btn-md btn-outline-success rounded-pill"
                onClick={handleDelete}
                style={{margin: '0px 20px'}}
              >
                Delete
              </button>
            </Card.Footer>
          </Card>
        </Col>
      )}

      {message && message[1].length > 1 && (
        <Col md={12} className="my-3">
          <Card className="bg-light">
            {message[1][0].fromUserType === 'COMPANY' &&
              !message[1][0].receivedMessageAttributes.messageType && (
                <Card.Header>Message From Company</Card.Header>
              )}
            {message[1][0].fromUserType === 'COMPANY' &&
              message[1][0].receivedMessageAttributes.messageType ===
                'Application' && (
                <Card.Header>Message From Company - Applied a job</Card.Header>
              )}

            {message[1][0].fromUserType === 'TALENT' &&
              !message[1][0].receivedMessageAttributes?.jobTitle && (
                <Card.Header>Message From {name}(Talent):</Card.Header>
              )}
            {message[1][0].fromUserType === 'TALENT' &&
              message[1][0].receivedMessageAttributes?.jobTitle && (
                <Card.Header>Message From {name}(Talent) for Job: </Card.Header>
              )}
            {message[1][0].fromUserType === 'INVESTOR' && (
              <Card.Header>
                Message From Investor: {message[1][0].fromUserKey}
              </Card.Header>
            )}
            <Card.Body>
              <dl className="row my-3">
                {message[1][0].fromUserType === 'TALENT' &&
                  message[1][0].receivedMessageAttributes?.jobTitle && (
                    <>
                      <dt className="col-sm-4 text-muted">Job Title:</dt>
                      <dd className="col-sm-8">
                        {message[1][0].receivedMessageAttributes?.jobTitle}
                      </dd>
                    </>
                  )}

                {message[1][0].fromUserType === 'COMPANY' &&
                  !message[1][0].receivedMessageAttributes.messageType && (
                    <>
                      <dt className="col-sm-4 text-muted">Company Name:</dt>
                      <dd className="col-sm-8">
                        {companyInfo && companyInfo.companyName}
                      </dd>
                    </>
                  )}
                {message[1][0].fromUserType === 'COMPANY' &&
                  message[1][0].receivedMessageAttributes.messageType ===
                    'Application' && (
                    <>
                      <dt className="col-sm-4 text-muted">Job Title:</dt>
                      <dd className="col-sm-8">
                        {message[1][0].receivedMessageAttributes.jobTitle}
                      </dd>
                    </>
                  )}
                {message[1][0].receivedMessageAttributes.originMsgBody && (
                  <>
                    <dt className="col-sm-4 text-muted">Original Messaage:</dt>
                    <dd className="col-sm-8">
                      {message[1][0].receivedMessageAttributes.originMsgBody}
                    </dd>
                  </>
                )}
              </dl>
              <ListGroup variant="flush">
                {message[1].map((item) => (
                  // eslint-disable-next-line react/jsx-key
                  <ListGroup.Item>
                    <dl className="row my-3">
                      <dt className="col-sm-4 text-muted">Subject:</dt>
                      <dd className="col-sm-8">
                        {item.receivedMessageAttributes?.subject}
                      </dd>

                      <dt className="col-sm-4 text-muted">Message:</dt>
                      <dd className="col-sm-8">
                        {item.receivedMessageAttributes?.body}
                      </dd>

                      <dt className="col-sm-4 text-muted">Receive Time:</dt>
                      <dd className="col-sm-8">
                        {moment(
                          item.receivedMessageAttributes?.modifyTime
                        ).format('MMMM Do YYYY, h:mm:ss a')}
                      </dd>
                    </dl>
                    {replyModal && (
                      <MessageReplyModal
                        showModal={replyModal}
                        setShowModal={setReplyModal}
                        message={message[1][0]}
                      />
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
            <Card.Footer>
              <button
                type="button"
                className="btn btn-md btn-outline-success rounded-pill"
                onClick={() => setReplyModal(true)}
              >
                Reply
              </button>
              <button
                type="button"
                className="btn btn-md btn-outline-success rounded-pill"
                onClick={handleDelete}
                style={{margin: '0px 20px'}}
              >
                Delete
              </button>
            </Card.Footer>
          </Card>
        </Col>
      )}
    </>
  );
}

MessageCard.propTypes = {
  message: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  apiServer: PropTypes.string.isRequired,
  messageList: PropTypes.object.isRequired,
  setMessageList: PropTypes.func.isRequired,
};

export default MessageInbox;
